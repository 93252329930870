import {Auth, connectAuthEmulator, EmailAuthProvider, getAuth, GoogleAuthProvider} from "firebase/auth";
import {auth} from "firebaseui";
import {firebaseApp} from "./firebase";

//////////////
// Centralizes all Firebase Auth configuration
//////////////

// See Docs here: https://www.npmjs.com/package/firebaseui#available-callbacks
export const FIREBASE_AUTH_UI_CONFIG = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "redirect",
  // signInFlow: "popup",

  // We will display Google as the only auth provider.
  // For customization, see: https://github.com/firebase/firebaseui-web#generic-oauth-provider
  signInOptions: [
    {
      provider: GoogleAuthProvider.PROVIDER_ID,
      // To override the full label of the button.
      // fullLabel: 'Login with Microsoft',
      customParameters: {
        // Forces account selection even when one account is available.
        prompt: "select_account"
      },
      // Required to enable ID token credentials for this provider.
      // This can be obtained from the Credentials page of the Google APIs console. Use the same OAuth client ID used
      // for the Google provider configured with GCIP or Firebase Auth.
      clientId: process.env.REACT_APP_FIREBASE_AUTH_CLIENT_ID,
    },
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      requireDisplayName: true,
      // Required to enable ID token credentials for this provider.
      // This can be obtained from the Credentials page of the Google APIs console. Use the same OAuth client ID used
      // for the Google provider configured with GCIP or Firebase Auth.
      clientId: process.env.REACT_APP_FIREBASE_AUTH_CLIENT_ID
      // scopes: [
      //   "https://www.googleapis.com/auth/userinfo.email",
      //   "https://www.googleapis.com/auth/userinfo.profile",
      //   "https://www.googleapis.com/auth/userinfo.openid",
      // ],
    }
    // {
    //   provider: EmailAuthProvider.PROVIDER_ID,
    //   signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    //   // fullLabel: "Sign in with email",
    //   requireDisplayName: true,
    //   clientId: process.env.REACT_APP_FIREBASE_AUTH_CLIENT_ID,
    //   // scopes: [
    //   //   "https://www.googleapis.com/auth/userinfo.email",
    //   //   "https://www.googleapis.com/auth/userinfo.profile"
    //   // ]
    // }
  ],
  immediateFederatedRedirect: false,
  // tosUrl and privacyPolicyUrl accept either url string or a callback function.
  // Terms of service url/callback.
  tosUrl:
    "https://legal.sentiment.fyi/tos.html",
  // Privacy policy url/callback.
  privacyPolicyUrl:
    "https://legal.sentiment.fyi/privacy.html",
  // Only set this if the `signInSuccessWithAuthResult` callback returns true, otherwise this will be ignored.
  // signInSuccessUrl: "/signed_in",
  callbacks:
    {
      // Avoid redirects after sign-in.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      signInSuccessWithAuthResult: function (authResult: any, redirectUrl: any) {
        // NOTE: This function is overwritten below!
        console.debug("Firebase authResult=%s", JSON.stringify(authResult));
        console.debug("Firebase redirectUrl=%s", redirectUrl);
        // Return false to
        return false;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      signInFailure: function (error: auth.AuthUIError): Promise<void> | void {
        // NOTE: This function is overwritten below!
        console.error(error);
        return;
      },
    }
  ,
  // uiShown: function () {
  //   // The widget is rendered.
  //   // Hide the loader text once the auth UI is displayed.
  //   const loader = document.getElementById("loader");
  //   if (loader) {
  //     loader.style.display = "none";
  //   }
  // }
  credentialHelper: auth.CredentialHelper.GOOGLE_YOLO
};

function initializeFirebaseAuth(): Auth {
  const auth: Auth = getAuth(firebaseApp);
  if (process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST) {
    FIREBASE_AUTH_UI_CONFIG.signInFlow = "popup";
    console.debug("Using Firebase Auth Emulator at " + process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST);
    connectAuthEmulator(auth, process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST);
  }
  return auth;
}

export const firebaseAuth: Auth = initializeFirebaseAuth();