import {initializeApp} from "firebase/app"
import {initializePerformance} from "firebase/performance";
import {initializeAnalytics} from "firebase/analytics";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {FirebaseApp} from "@firebase/app";

//////////////
// Centralizes all Firebase configuration
//////////////

// To setup custom auth domain, see https://cloud.google.com/identity-platform/docs/show-custom-domain
const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

console.debug("firebase.initializeApp starting...")
const app: FirebaseApp = initializeApp(FIREBASE_CONFIG);
if (window.location.hostname === "localhost") {
  console.debug("Using Firestore Emulator at http://localhost:5555");
  const db = getFirestore(app);
  connectFirestoreEmulator(db, "localhost", 5555);
}
console.debug("firebase.initializeApp complete...")

initializePerformance(app);
initializeAnalytics(app);

// Export firebaseApp. It will be imported into your React app whenever it is needed
export const firebaseApp: FirebaseApp = app;
