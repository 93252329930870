import {useNavigate} from "react-router-dom";
import {SLASH} from "../../../model/constants";
import {Button} from "@chakra-ui/react";
import {firebaseAuth} from "../../../utils/firebase_auth";

const SignOutButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      width={"100%"}
      justifyContent={"flex-start"}
      textAlign={"left"}
      variant={"ghost"}
      fontWeight={"normal"}
      size={"sm"}
      onClick={
        () => {
          firebaseAuth.signOut().then(
            //onFulfilled
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            _ => navigate(SLASH),
            // onRejected
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            _ => navigate(SLASH)
          );
        }
      }
    >Sign Out</Button>
  );
};

export default SignOutButton;