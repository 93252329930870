// React 17
import React from "react";
import * as Sentry from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {createRoot} from "react-dom/client";

if (!window.location.host.startsWith("localhost")) {
  Sentry.init({
    dsn: "https://db8c5a4dea364bba944d0f75f4ae53ee@o4505267821871104.ingest.sentry.io/4505267824295936",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/api\.sentiment\.fyi/],
      })
      // ,
      // Uncomment below to enable replay in sentry.io
      // new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a
    // lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where
    // errors occur.
    replaysOnErrorSampleRate: 1.0,
  });
}

// React 17
// ReactDOM.render(<App/>, document.getElementById("root"));

// React 18
// Note that enabling this breaks FirebaseUI in local development mode using the emulator. For now, we just use 'popup'
// auth instead of redirect auth; but on a real server, 'redirect' mode works find.
const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // <-- ! due to typescript since container is never null
root.render(<App/>);

if (!window.location.host.startsWith("localhost")) {
// To send to an analytics endpoint, see: https://bit.ly/CRA-vitals
  reportWebVitals(console.debug);
}