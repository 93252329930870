import React, {KeyboardEvent, SetStateAction, useCallback, useEffect, useState} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react"
import {Claim} from "../../client/model";
import {API_URL_SLASH_CLAIMS} from "../../model/url_constants";
import {constructClaimButtonText,} from "./create_claim_messages";
import {ClaimType} from "./create_claim";
import {useAuth} from "../auth/AuthContext";
import sentimentAxios from "../../utils/axios";
import {claimSuggestions} from "../../utils/claim_suggestions";

interface CreateSubClaimProps {
  parentClaimId: string, // The id of the parent claim to create this claim under.
}

/**
 * A component that allows a new claim to be created.
 * @param props
 */
const CreateSubClaim = (props: CreateSubClaimProps) => {

  const {isSignedIn} = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isError, setIsError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inputErrorMessage, setInputErrorMessage] = useState("")

  // Claim Error Alert
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorAlertTitle, setErrorAlertTitle] = useState("");
  const [errorAlertDescription, setErrorAlertDescription] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // handle changes to the "value" state via updating it with e.target.value
  const handleChange = useCallback((e: { target: { value: SetStateAction<string>; }; }) => {
    setInputValue(e.target.value)
    setSubmitted(false);
  }, []);

  // const toast = useToast();

  /**
   * Create a Claim on the server...
   */
  const createClaimOnServer = async () => {
    setSubmitted(true);

    // Check the input...
    // if (inputValue === undefined || inputValue.length <= 0) {
    //     setSubmitted(false);
    //     setIsError(true);
    //     return;
    // }

    sentimentAxios.post(
      API_URL_SLASH_CLAIMS, // <-- url
      {
        "title": inputValue,
        "parentClaimId": props.parentClaimId
      }, // <-- data
      {
        validateStatus: function (status) {
          return status === 201 || status >= 400;
        }
      } // <-- config
    ).then(response => {
      console.debug("Response Status=" + response.status);
      if (response.status === 201) {
        const createdClaim: Claim = response.data as Claim;
        console.debug("Created Claim: " + JSON.stringify(createdClaim))
        setInputValue("");
      } else if (response.status >= 400) {
        // Try to return a problem
        showErrorAlertFromServer(response.data);
      } else {
        // Unexpected Error.
        showErrorAlertFromServer({
          "title": "Oops...",
          "details": "An unexpected error occurred. Please try your request again in a few moments."
        });
      }
    }).catch(e => {
      console.debug("Error Creating New Claim: " + JSON.stringify(e));
      showErrorAlertFromServer(e);
    });
  }

  function handleEnterKeyPress<T = Element>(f: () => void) {
    return handleKeyPress<T>(f, "Enter")
  }

  function handleKeyPress<T = Element>(f: () => void, key: string) {
    return (e: KeyboardEvent<T>) => {
      if (e.key === key) {
        f()
      }
    }
  }

  const isInputValid = (): boolean => {
    return inputValue !== undefined && inputValue.length > 0;
  }

  /**
   * Displays the "can't be empty" error.
   */
  useEffect(() => {

    if (!isInputValid() && submitted) {
      //setInputErrorMessage("Subclaims must not be empty.");
      //setIsError(true);
    } else {
      //setInputErrorMessage("");
      //setIsError(false);
    }

    // if (submitted) {
    //     toast({
    //         title: `"Oops..."` + constructClaimsNotEmptyErrorDescription(ClaimType.SubClaim),
    //         status: "error",
    //         isClosable: true,
    //     })
    //
    //     // setErrorAlertTitle("Oops...")
    //     // setErrorAlertDescription(constructClaimsNotEmptyErrorDescription(ClaimType.SubClaim))
    //     // setShowErrorAlert(true);
    // } else {
    //     setErrorAlertDescription("")
    //     setShowErrorAlert(false);
    // }
  }, [isInputValid, inputValue, submitted]);

  function showErrorAlertFromServer(error: any) {
    // Handle a Problem from the server, or just use the generic error JS contract otherwise.
    if (error.title) {
      setErrorAlertTitle(error.title)
    } else {
      setErrorAlertTitle("Oops...")
    }
    if (error.detail) {
      setErrorAlertDescription(error.detail)
    } else {
      setErrorAlertDescription(error.message)
    }
    setShowErrorAlert(true);
  }

  const helpTextColor = useColorModeValue("white.600", "gray.500");
  // const errorAlertJsx = showErrorAlert &&
  //     <Alert status='error'
  //            variant='left-accent'
  //            alignContent={"left"}
  //            marginBottom={"1rem"}>
  //         <AlertIcon/>
  //         <Box>
  //             {/*<AlertTitle>Oops...</AlertTitle>*/}
  //             {/*<AlertDescription>Claims can't be empty</AlertDescription>*/}
  //             <AlertTitle>{errorAlertTitle}</AlertTitle>
  //             <AlertDescription>{errorAlertDescription}</AlertDescription>
  //         </Box>
  //         {/*<CloseButton*/}
  //         {/*    alignSelf='flex-start'*/}
  //         {/*    position='relative'*/}
  //         {/*    right={-1}*/}
  //         {/*    top={-1}*/}
  //         {/*    onClick={() => setIsAlertVisible(false)}*/}
  //         {/*/>*/}
  //     </Alert>;

  // TODO: Render for mobile too.

  return (
    <Box visibility={"visible"}
         minW={{base: "350px", md: "640px", lg: "960px"}}
         padding={"0rem 1rem"}
    >
      <Stack direction='column' spacing={4} align='center'>
        {/*<InputGroup size='sm'>*/}
        {/*    <InputLeftAddon children='Subclaim:'/>*/}
        {/*    <Input placeholder={constructClaimPrompt(ClaimType.SubClaim)}*/}
        {/*           _placeholder={{color: helpTextColor, fontStyle: 'italic'}}*/}
        {/*           value={inputValue}*/}
        {/*           onChange={handleChange}*/}
        {/*           onKeyDown={handleEnterKeyPress(createClaimOnServer)}*/}
        {/*    />*/}
        {/*</InputGroup>*/}
        {/*<ButtonGroup>*/}
        {/*    <Button colorScheme='orange' size={"sm"} onClick={createClaimOnServer}>*/}
        {/*        {constructClaimButtonText(ClaimType.SubClaim)}*/}
        {/*    </Button>*/}
        {/*</ButtonGroup>*/}


        <FormControl isInvalid={isError}>
          <InputGroup size='sm'>
            <InputLeftAddon children='Subclaim:'/>
            <Input
              type='text'
              placeholder={claimSuggestions.getSuggestedSubclaim()}
              _placeholder={{color: helpTextColor, fontStyle: "italic"}}
              value={inputValue}
              isDisabled={!isSignedIn()}
              onChange={handleChange}
              onKeyDown={handleEnterKeyPress(createClaimOnServer)}
              marginBottom={"0.75rem"}
              maxLength={200}
            />
          </InputGroup>
          {isError ? (
            <FormErrorMessage fontStyle={"italic"}>{inputErrorMessage}</FormErrorMessage>
          ) : <span/>}
          <ButtonGroup>
            <Button
              colorScheme='orange'
              isDisabled={!isSignedIn() || !isInputValid()}
              size={"sm"}
              onClick={createClaimOnServer}
            >
              {constructClaimButtonText(ClaimType.SubClaim)}
            </Button>
          </ButtonGroup>

        </FormControl>


      </Stack>
    </Box>
  );
};

export default CreateSubClaim;