import React, {KeyboardEvent, SetStateAction, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react"
import {Claim} from "../../client/model";
import {API_URL_SLASH_CLAIMS} from "../../model/url_constants";
import {constructClaimsNotEmptyErrorDescription,} from "./create_claim_messages";
import {useAuth} from "../auth/AuthContext";
import sentimentAxios from "../../utils/axios";
import {claimSuggestions} from "../../utils/claim_suggestions";

/**
 * Different types of Claim to create.
 */
// TODO: Remove ClaimType?
export enum ClaimType {
  /**
   * A vanilla claim.
   */
  Claim,
  /**
   * A subclaim for a claim.
   */
  SubClaim,
  /**
   * A summary claim. This is a sub-claim that summarizes all direct children sub-claims of a given claim.
   */
  SummaryClaim,
  /**
   * A parent claim. This will place the current claims as a sub-claim to the new claim.
   */
  ParentClaim
}

export interface CreateClaimProps {
  claimType: ClaimType;
}

/**
 * A component that allows a new claim to be created.
 * @param props
 * @constructor
 */
const CreateClaim = (props: CreateClaimProps) => {

  const {isSignedIn} = useAuth();

  const [submitted, setSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // Claim Error Alert
  const [errorAlertTitle, setErrorAlertTitle] = useState("");
  const [errorAlertDescription, setErrorAlertDescription] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // handle changes to the "value" state via updating it with e.target.value
  const handleChange = useCallback((e: { target: { value: SetStateAction<string>; }; }) => {
    setInputValue(e.target.value)
    setSubmitted(false);
  }, []);
  const navigate = useNavigate();

  /**
   * Create a Claim on the server...
   */
  const createClaimOnServer = async () => {
    setSubmitted(true);

    // Check the input...
    if (inputValue === undefined || inputValue.length <= 0) {
      setSubmitted(true);
      return;
    }

    sentimentAxios.post(
      API_URL_SLASH_CLAIMS, // <-- url
      {"title": inputValue}, // <-- data
      {
        validateStatus: function (status) {
          return status === 201 || status >= 400;
        }
      } // <-- config
    ).then(response => {
      console.debug("Response Status=" + response.status);
      if (response.status === 201) {
        const createdClaim: Claim = response.data as Claim;
        console.debug("Created Claim: " + JSON.stringify(createdClaim))
        // This will redirect...
        navigate("/claims/" + createdClaim.id);
      } else if (response.status >= 400) {
        // Try to return a problem
        showErrorAlertFromServer(response.data);
      } else {
        // Unexpected Error.
        showErrorAlertFromServer({
          "title": "Oops...",
          "details": "An unexpected error occurred. Please try your request again in a few moments."
        });
      }
    }).catch(e => {
      console.debug("Error Creating New Claim: " + JSON.stringify(e));
      showErrorAlertFromServer(e);
    });
  }

  function handleEnterKeyPress<T = Element>(f: () => void) {
    return handleKeyPress<T>(f, "Enter")
  }

  function handleKeyPress<T = Element>(f: () => void, key: string) {
    return (e: KeyboardEvent<T>) => {
      if (e.key === key) {
        f()
      }
    }
  }

  const isInputValid = (): boolean => {
    // return (signedInSentimentUser !== undefined) && inputValue !== undefined && inputValue.length > 0;
    if (isSignedIn() && inputValue) {
      return inputValue.length > 0;
    } else {
      return false;
    }
  }

  /**
   * Displays the "can't be empty" error.
   */
  useEffect(() => {
    if (submitted && (inputValue === undefined || inputValue.length <= 0)) {
      setErrorAlertTitle("Oops...")
      setErrorAlertDescription(constructClaimsNotEmptyErrorDescription(props.claimType))
      setShowErrorAlert(true);
    } else {
      setErrorAlertDescription("")
      setShowErrorAlert(false);
    }
  }, [props.claimType, inputValue, submitted]);

  function showErrorAlertFromServer(error: any) {
    // Handle a Problem from the server, or just use the generic error JS contract otherwise.
    if (error.title) {
      setErrorAlertTitle(error.title)
    } else {
      setErrorAlertTitle("Oops...")
    }
    if (error.detail) {
      setErrorAlertDescription(error.detail)
    } else {
      setErrorAlertDescription(error.message)
    }
    setShowErrorAlert(true);
  }

  const helpTextColor = useColorModeValue("white.600", "gray.500");
  const errorAlertJsx = showErrorAlert &&
    <Alert status='error'
           variant='left-accent'
           alignContent={"left"}
           marginBottom={"1rem"}>
      <AlertIcon/>
      <Box>
        {/*<AlertTitle>Oops...</AlertTitle>*/}
        {/*<AlertDescription>Claims can't be empty</AlertDescription>*/}
        <AlertTitle>{errorAlertTitle}</AlertTitle>
        <AlertDescription>{errorAlertDescription}</AlertDescription>
      </Box>
      {/*<CloseButton*/}
      {/*    alignSelf='flex-start'*/}
      {/*    position='relative'*/}
      {/*    right={-1}*/}
      {/*    top={-1}*/}
      {/*    onClick={() => setIsAlertVisible(false)}*/}
      {/*/>*/}
    </Alert>;

  /**
   * The look and feel of a normal "Creat Claim" component.
   */
  const createClaimComponent = (
    <Center>
      <Container
        paddingY={"1rem"}
        paddingX={"0rem"}
        m={"0rem"}
        // w={{base: "375px", md: "640px", lg: "960px"}}
        maxW={{base: "375px", md: "640px", lg: "960px"}}
        // width={"max-content"}
      >
        <Stack visibility={"visible"} direction='column' align='left'>
          {errorAlertJsx}
        </Stack>
        <Stack direction='column'
               spacing={4}
               align='center'>
          <InputGroup size='sm'>
            <InputLeftAddon children='Claim:'/>
            <Input placeholder={claimSuggestions.getSuggestedClaim()}
                   _placeholder={{color: helpTextColor, fontStyle: "italic"}}
                   value={inputValue}
                   onChange={handleChange}
                   onKeyDown={handleEnterKeyPress(createClaimOnServer)}
            />
          </InputGroup>
          <ButtonGroup>
            <Button colorScheme='orange'
                    isDisabled={!isInputValid()}
                    onClick={createClaimOnServer}>Make Claim</Button>
            <Button
              onClick={() => navigate(-1)}>Cancel</Button>
          </ButtonGroup>
        </Stack>
      </Container>
    </Center>
  );

  /**
   * The look and feel of a normal "Creat Claim" component.
   */
  // const createSubclaimComponent = (
  //     <Box margin={"1rem"}>
  //         <Stack visibility={"visible"} direction='column' align='left'>
  //             {errorAlertJsx}
  //         </Stack>
  //         <Stack direction='column' spacing={4} align='center'>
  //             <InputGroup size='sm'>
  //                 <InputLeftAddon children='Subclaim:'/>
  //                 <Input placeholder={constructClaimPrompt(props.claimType)}
  //                        _placeholder={{color: helpTextColor, fontStyle: 'italic'}}
  //                        value={inputValue}
  //                        onChange={handleChange}
  //                        onKeyDown={handleEnterKeyPress(createClaimOnServer)}
  //                 />
  //             </InputGroup>
  //             <ButtonGroup>
  //                 <Button colorScheme='orange' onClick={createClaimOnServer}>
  //                     {constructClaimButtonText(props.claimType)}
  //                 </Button>
  //                 <Button onClick={() => history.goBack()}>Cancel</Button>
  //             </ButtonGroup>
  //         </Stack>
  //     </Box>
  // );

  switch (props.claimType) {
    // case ClaimType.SubClaim: {
    //     // TODO: Using props in this way is not necessary.
    //     return createSubclaimComponent;
    // }
    case ClaimType.Claim:
      return createClaimComponent;
    case ClaimType.ParentClaim:
    case ClaimType.SummaryClaim:
    default: {
      return createClaimComponent
    }
  }
};

export default CreateClaim;