import React, {useEffect} from "react";
import {Box, useColorModeValue} from "@chakra-ui/react";
import {STRIPE_PRICING_TABLE_SCRIPT_URL} from "../../model/stripe_constants";

/**
 * Properties required by this pricing table component.
 */
type StripePricingTableProps = {

  /**
   * The unique identifier inside of Stripe for the pricing table to display.
   */
  pricingTableId: string,

  /**
   * On the client side: Can be publicly accessible in your web or mobile app’s client-side code (such
   * as checkout.js) to securely collect payment information, such as with Stripe Elements. By default,
   * Stripe Checkout securely collects payment information.
   *
   * @see "https://stripe.com/docs/keys#obtain-api-keys"
   */
  publishableKey: string,

  /**
   * Use `client-reference-id` to attach a unique string of your choice to the Checkout Session. The string can be a
   * customer ID or a cart ID (or similar) that you use to reconcile the Session with your internal systems. If you pass
   * this parameter to your <stripe-buy-button>, it’s sent in the `checkout.session.completed` webhook upon payment
   * completion. `client-reference-id` can be composed of alphanumeric characters, dashes, or underscores, and be any
   * value up to 200 characters. Invalid values are silently dropped, but your payment page continues to work as
   * expected.
   *
   * In Sentiment, this is the user's Sentiment UserId, which is a UUID.
   */
  clientReferenceId: string | undefined,

  /** Use customer-email to prefill the email address on the payment page. When the property is set, the buy button
   * passes it to the Checkout Session’s customer_email attribute. The email address can’t be edited by the customer on
   * the payment page. customer-email must be a valid email. Invalid values are silently dropped, but your payment pages
   * continues to work as expected.
   */
  customerEmail: string | undefined,
}

const StripePricingTable = (props: StripePricingTableProps) => {

  console.debug("StripePricingTableProps: " + JSON.stringify(props));

  useEffect(() => {
    const script: HTMLScriptElement = document.createElement("script");
    script.src = STRIPE_PRICING_TABLE_SCRIPT_URL;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const stripePricingTable = React.createElement("stripe-pricing-table", {
    "pricing-table-id": props.pricingTableId,
    "publishable-key": props.publishableKey,
    "client-reference-id": props.clientReferenceId,
    "customer-email": props.customerEmail
  });

  const flexBgColor = useColorModeValue("gray.50", "gray.800");
  // const stackBgColorMode = useColorModeValue("white", "gray.700");

  return (
    <Box
      bg={flexBgColor}
    >
      {stripePricingTable}
    </Box>
  );
};

export default StripePricingTable;