// Contains business logic to render meta tags inside a React App.
// Note that this logic is distinct from the GCP Function that runs in front of Firebase hosting responses, but
// in the future these two sets of logic should likely be combined.
import {Claim, ClaimWithIncludes} from "../client/model/claim";
import {ClaimIncludes, findUserName} from "../client/model/with_includes";

/**
 * Defines fields for Open Graph.
 *
 * @see "https://medium.com/@jalalio/dynamic-og-tags-in-your-statically-firebase-hosted-polymer-app-476f18428b8b"
 * @see "https://ogp.me/"
 * @see "https://gist.github.com/JaySunSyn/94f58d7fc2815af63693828c42b1e3e9"
 */
export interface OpenGraph {

  /**
   * og:title - The title of your object as it should appear within the graph, e.g., "The Rock".
   */
  title: string;

  /**
   * og:description: A one to two sentence description of your object.
   */
  description?: string;

  /**
   *  og:type - The type of your object, e.g., "video.movie". Depending on the type you specify,
   *  other properties may also be required.
   */
  type: string,

  /**
   * og:url - The canonical URL of your object that will be used as its permanent ID in the graph,
   * e.g., "https://www.imdb.com/title/tt0117500/".
   */
  url: string,

  /**
   * og:site_name: If your object is part of a larger website, the name which should be displayed for the overall
   * site. e.g., "IMDb".
   */
  siteName: string;

  /**
   * og:locale - The locale these tags are marked up in. Of the format language_TERRITORY. Default is en_US.
   */
  locale: string;

  // Optional OG tags

  /**
   *  og:image - An image URL which should represent your object within the graph.
   */
  imageUrl?: string,

  /**
   * e.g., "3"
   */
  numSubclaims?: number,

  /**
   * e.g., "John Smith"
   */
  claimAuthorName?: string

  /**
   * e.g., "74% Agreeing"
   */
  agreement?: string
}

// This is safe because these values will be rendered by Helmet when in React. When in the Firebase Function, the
// values will be purified using DOMPurify.
export const populateOpenGraph = (claimWithIncludes: ClaimWithIncludes | undefined): OpenGraph => {
  if (claimWithIncludes) {
    const claim = claimWithIncludes.data as Claim;
    const claimId = claimWithIncludes.data.id as ClaimIncludes;
    const includes = claimWithIncludes.includes;
    const numSubclaims = Object.keys(includes.subclaims ?? {}).length;
    const percentAgreeing: string =
      // 90% Agreeing (250) / 10% Disagreeing (25)
      `${claim.claimMetrics.percentAgreeing} Agreeing (${claim.claimMetrics.numAgreeVotes})` +
      `| ${claim.claimMetrics.percentDisagreeing} Disagreeing (${claim.claimMetrics.numDisagreeVotes})`;

    return {
      ...DEFAULT_ORG,
      title: claim.text + " | Sentiment",
      url: "https://sentiment.fyi/claims/" + claimId,
      numSubclaims: numSubclaims,
      claimAuthorName: findUserName(claim.authorId, claimWithIncludes),
      agreement: percentAgreeing,
    } as OpenGraph;
  } else {
    return DEFAULT_ORG;
  }
};

export const DEFAULT_ORG: OpenGraph = {
  type: "website",
  siteName: "Sentiment",
  title: "A Sentiment Claim",
  description: "Sentiment informs better decisions using statements called claims. " +
    "Post a claim. See who agrees. Then make sub-claims for deeper alignment.",
  url: "https://sentiment.fyi",
  locale: "en_US",
};
