import React, {useRef, useState} from "react";
import {VoteButton} from "../VoteButton"
import {AxiosResponse} from "axios";
import {Claim, ClaimContestOption, ClaimContestSelection, ClaimMetrics} from "../../../client/model";
import {HStack, VStack} from "@chakra-ui/react";
import {fetchVoteUrl} from "../../../model/url_constants";
import {OOPS_HEADER, OOPS_MESSAGE_GENERIC} from "../../../model/constants";
import {useAuth} from "../../auth/AuthContext";
import sentimentAxios from "../../../utils/axios";

/**
 * This Component holds both vote buttons, in addition to the total number of agree/disagree votes for a claim. The
 *  children components are owned by this component, which manages state for any subcomponents.
 */
interface VoteButtonsContainerProps {
  // Callback to update state in the parent, if desired.
  // onClaimVoteTotalsUpdated(numAgreeVotes: number, numDisagreeVotes: number): void;

  // Callback to update the contest selection in the parent state.
  onContestSelectionChange(newContestSelection: ClaimContestSelection): void;

  claimId: string, // The id of the Claim being voted on.
  claim?: Claim, // the claim being voted on.
  claimContestSelection?: ClaimContestSelection,
  // "normal" and "small" are valid values; undefined == "normal"
  buttonSize: string,
  isLoading: boolean,
}

const SubclaimVoteButtonsContainer = (props: VoteButtonsContainerProps) => {

  const {getSignedInSentimentUser, isSignedIn} = useAuth();
  const signedInSentimentUser = getSignedInSentimentUser();

  const errorUpdatingVoteInfo = useRef(false);

  const [errorMessageHeader, setErrorMessageHeader] = useState("");
  const [errorMessageBody, setErrorMessageBody] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMoreInfoLink, setErrorMoreInfoLink] = useState("");

  const [isAgreeVotingInProgress, setIsAgreeVotingInProgress] = useState(false);
  const [isDisagreeVotingInProgress, setIsDisagreeVotingInProgress] = useState(false);

  const voteUrl = fetchVoteUrl(getSignedInSentimentUser()?.id ?? "", props.claimId);

  // const voteUrl = useMemo(
  //   () => fetchVoteUrl(signedInSentimentUser?.id ?? "", props.claimId),
  //   [props.claimId, sentimentAuth]
  // );

  /**
   * Callback that updates the signed-in user's Claim Contest Selection (i.e., "vote") on the Sentiment API server.
   */
  const updateContestSelectionOnServer = async (voteUrl: string, contestSelection: ClaimContestSelection) => {
    if (contestSelection.selectedOption === ClaimContestOption.AGREE) {
      setIsAgreeVotingInProgress(true);
    } else {
      setIsDisagreeVotingInProgress(true);
    }
    errorUpdatingVoteInfo.current = false;

    sentimentAxios.put(voteUrl, contestSelection).then(response => {
      errorUpdatingVoteInfo.current = false;
      const contestSelectionAfterPut: ClaimContestSelection = fromRequestData(response.data);
      props.onContestSelectionChange(contestSelectionAfterPut);
    }).catch(e => {
      console.debug("ContestSelection Update FAILURE: " + JSON.stringify(e));
      errorUpdatingVoteInfo.current = true;
      const problem: any | undefined = e.response?.data;
      if (problem && problem.status === 400) {
        console.debug("ERROR 400");
        if (problem.type.includes("insufficient-funds")) {
          setErrorMessageHeader(OOPS_HEADER);
          setErrorMessageBody("You need SNT to vote on claims")
        }
      } else if (problem && problem.status === 404) {
        console.debug("ERROR 404");
        setErrorMessageHeader(OOPS_HEADER)
        setErrorMessageBody(
          "The claim you were trying to vote on doesn't exist. " +
          "Wait a few minutes and try your request again"
        );
      } else {
        // Fall-through to here
        setErrorMessageHeader(OOPS_HEADER)
        setErrorMessageBody(OOPS_MESSAGE_GENERIC);
      }
      setErrorMoreInfoLink(problem.type);
      console.debug(errorMessageHeader + errorMessageBody);
    }).finally(() => {
      setIsAgreeVotingInProgress(false);
      setIsDisagreeVotingInProgress(false);
    });
  };

  /**
   * Whenever a Contest Selection button is clicked, we want to process the intent according to the following rules:
   *
   * 1. If no user is signed in, both buttons will be disabled.
   * 2. If the user is not currently voting, but clicks this button, then we indicate a vote of 1.
   * 3. If the user _is_ currently voting, and clicks the button they're already voting for, then this is considered
   * an "un-vote", so we indicate a vote of 0.
   * 4. If the user _is_ currently voting, and clicks the button they're NOT voting for (i.e., the other button),
   * then this is considered a "switch-vote", so we indicate a vote of 1.
   *
   * 1. If the user is not voting for either choice, then add a new contest selection.
   * 2. If the user is voting
   * This value may be updated later via a render (in which case the parent will provide a new Claim via props).
   *
   * @param selectedOption
   */
  const handleContestSelectionClick = (selectedOption: ClaimContestOption) => {
    if (isAgreeVotingInProgress || isDisagreeVotingInProgress) {
      // Disagree voting is in progress...
      return;
    }

    // (1) Only process votes if the user is signed in and another vote is not in progress.
    if (isSignedIn()) {
      const signedInUserClaimContestSelection: ClaimContestSelection | undefined = props.claimContestSelection;

      let claimContestSelection: ClaimContestSelection;
      // if (signedInUserClaimContestSelection) {
      // User is voting...
      if (signedInUserClaimContestSelection && +signedInUserClaimContestSelection.numVotes > 0) {
        // User is voting...
        if (signedInUserClaimContestSelection.selectedOption === selectedOption) {
          // ... for the clicked option, but wants to Un-vote.
          claimContestSelection = {
            claimId: props.claimId,
            numVotes: "0",
            selectedOption: selectedOption,
          } as ClaimContestSelection;
        } else {
          // ... but wants to change their vote.
          claimContestSelection = {
            claimId: props.claimId,
            numVotes: "1",
            selectedOption: selectedOption,
          } as ClaimContestSelection;
        }
      } else {
        // User previously voted, but isn't anymore...
        claimContestSelection = {
          claimId: props.claimId,
          numVotes: "1",
          selectedOption: selectedOption,
        } as ClaimContestSelection;
      }

      console.debug(
        "handleContestSelectionClick updateContestSelectionOnServer With: " + JSON.stringify(claimContestSelection)
      );
      props.onContestSelectionChange(claimContestSelection);
      return updateContestSelectionOnServer(voteUrl, claimContestSelection)
        // This preemptively updates the UI (while we're waiting for the actual value to come back) on the
        // assumption that the update will succeed.
        .then(() => {
          // console.debug("After updateContestSelectionOnServer Promise,
          // claimContestSelection=" + JSON.stringify(claimContestSelection));
        });

    } else {
      // do nothing...
    }

  }

  // TODO: Remove this.
  const fromRequestData = (data: AxiosResponse): ClaimContestSelection => {
    const contestSelection: ClaimContestSelection = data as unknown as ClaimContestSelection;
    return {
      claimId: props.claimId,
      selectedOption: contestSelection.selectedOption,
      numVotes: contestSelection.numVotes,
      // createdAt: contestSelection.createdAt,
      // updatedAt: contestSelection.updatedAt
    };
  }

  const claimMetrics: ClaimMetrics | undefined = props.claim?.claimMetrics;
  // console.debug("claimMetrics=" + JSON.stringify(claimMetrics));
  return (
    <VStack>
      <HStack>
        <VoteButton
          buttonType={ClaimContestOption.AGREE}
          onClick={handleContestSelectionClick}
          votingEnabled={(signedInSentimentUser || false) && !isDisagreeVotingInProgress && !props.isLoading}
          isVotingInProgress={isAgreeVotingInProgress}
          claimMetrics={claimMetrics}
          signedInUserContestSelection={props.claimContestSelection}
          buttonSize={props.buttonSize}
        />
        <VoteButton
          buttonType={ClaimContestOption.DISAGREE}
          onClick={handleContestSelectionClick}
          votingEnabled={(signedInSentimentUser || false) && !isAgreeVotingInProgress && !props.isLoading}
          isVotingInProgress={isDisagreeVotingInProgress}
          claimMetrics={claimMetrics}
          signedInUserContestSelection={props.claimContestSelection}
          buttonSize={props.buttonSize}
        />
      </HStack>

      {/*TODO: Make this a popover so that it doesn't show up in the UI.*/}
      {/*<Alert status='error' visibility={errorUpdatingVoteInfo.current ? 'visible' : 'hidden'}>*/}
      {/*    <AlertIcon/>*/}
      {/*    <Box>*/}
      {/*        <AlertTitle>{errorMessageHeader}</AlertTitle>*/}
      {/*        <AlertDescription>*/}
      {/*            <Box>*/}
      {/*                {errorMessageBody}&nbsp;(*/}
      {/*                <Box as={'span'}>*/}
      {/*                    <Link href={errorMoreInfoLink}>details</Link>*/}
      {/*                </Box>*/}
      {/*                )*/}
      {/*            </Box>*/}

      {/*        </AlertDescription>*/}
      {/*    </Box>*/}
      {/*</Alert>*/}

    </VStack>
  );
}

export {SubclaimVoteButtonsContainer}