import {Badge} from "@chakra-ui/react";
import React from "react";

interface SntBadgeProps {
  sntAmount: number
  hideDiamond?: boolean
}

// Consistently render a badge showing the amount of SNT that a user has.
export const SntBadge = (props: SntBadgeProps): JSX.Element => {

  let badge;
  if (props.hideDiamond) {
    badge = <Badge>{props.sntAmount} SNT</Badge>
  } else {
    // NOTE: &nbsp; characters don't render properly if we pull them out to a string
    badge = <Badge mr={"3px"}>💎&nbsp;&nbsp;{props.sntAmount} SNT</Badge>
  }

  return badge;
}