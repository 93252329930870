import {sendEmailVerification, User} from "firebase/auth";
import {CreateToastFnReturn} from "@chakra-ui/react";

/**
 * Determines if a user has a verified email address.
 *
 * @param user A signed-in user.
 * @return {@code true} has a verified email address; {@code false} otherwise.
 */
export const isEmailVerified = (user?: User): boolean => {
  return !!user && user.emailVerified;
};

/**
 * Helper method to send a verification email.
 * @param toast A {@link CreateToastFnReturn}.
 * @param user An optionally present {@link User}, typically representing the signed-in user.
 */
export const sendEmailVerificationHelper = async (user: User, toast?: CreateToastFnReturn): Promise<void> => {
    console.debug("Sending new user email verification...");

    if (user) {
      // See https://firebase.google.com/docs/auth/web/passing-state-in-email-actions
      // const actionCodeSettings: ActionCodeSettings = {
      //   url: "https://development.sentiment.fyi/sign_in?email=" + user.email,
      // };

      return await sendEmailVerification(user)
        .catch(error => {
            console.error(error);
            if (toast) {
              toast({
                title: "Oops, something went wrong",
                description: "We weren't able to send your verification email. Please try again. Error was: "
                  + error.description,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            } else {
              return Promise.resolve();
            }
          }
        );
    } else {
      return Promise.resolve();
    }
  }
;