import {useNavigate} from "react-router-dom";
import {Button} from "@chakra-ui/react";
import {StarIcon, TimeIcon} from "@chakra-ui/icons";
import {STRIPE_CUSTOMER_PORTAL_URL} from "../../../model/stripe_constants";
import React from "react";
import {SLASH_SUBSCRIBE} from "../../../model/url_constants";

export const BillingButton = () => {
  return (
    <Button
      width={"100%"}
      justifyContent={"flex-start"}
      textAlign={"left"}
      variant={"ghost"}
      fontWeight={"normal"}
      size={"sm"}
      // alignContent={"left"}
      // alignItems={"left"}
      // bgColor={"red"}
      leftIcon={<TimeIcon/>}
      onClick={
        () => {
          // Prefer window.location.replace per https://stackoverflow.com/questions/503093
          window.location.replace(STRIPE_CUSTOMER_PORTAL_URL);
        }
      }
    >Manage Subscription</Button>
  );
};

export const SubscribeButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      width={"100%"}
      justifyContent={"flex-start"}
      textAlign={"left"}
      variant={"ghost"}
      fontWeight={"normal"}
      size={"sm"}
      leftIcon={<StarIcon/>}
      onClick={
        () => {
          navigate(SLASH_SUBSCRIBE)
        }
      }
    >Subscribe</Button>
  );
};