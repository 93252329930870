import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack
} from "@chakra-ui/react";
import {firebaseAuth} from "../../utils/firebase_auth";
import {useLocation} from "react-router-dom";
import {useAuth} from "../../components/auth/AuthContext";

export const ErrorView = () => {

  const location = useLocation();

  let errorMessage: string = location && location.state && location.state.error && location.state.error.message;
  if (errorMessage === undefined) {
    errorMessage = "Unknown";
  }
  const signedIntoFirebase = !!(firebaseAuth.currentUser);
  const {getSignedInSentimentUser} = useAuth();

  return <Center alignContent={"center"}>
    <VStack width={"100%"}>
      <Alert
        status='error'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0}/>
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          Houston, we have a problem.
        </AlertTitle>
        <AlertDescription maxWidth='lg'>
          <br/><b>Claim</b>: You should try again in a moment, or request a different page.
        </AlertDescription>
      </Alert>
      <Popover>
        <PopoverTrigger>
          <Button colorScheme={"blue"}>Show Info</Button>
        </PopoverTrigger>
        <PopoverContent maxWidth={"100%"}>
          <PopoverArrow/>
          <PopoverCloseButton/>
          <PopoverHeader fontWeight={"bold"}>Error Info</PopoverHeader>
          <PopoverBody>
            <pre>
              <b>signedIntoFirebase</b>={signedIntoFirebase ? "true" : "false"}
            </pre>
            <pre>{JSON.stringify(getSignedInSentimentUser(), null, 2)}</pre>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </VStack>
  </Center>
}