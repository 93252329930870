import React, {createContext, useContext, useEffect, useState} from "react";
import {AuthState, SentimentAuth, SignedInUserLoadingState} from "./sentiment_auth";
import {SentimentConfig} from "../../AppConfig";
import {SentimentUser} from "../../client/model";

// This element was patterned off of:
// https://github.com/divanov11/React-Auth-Private-Routes-With-Appwrite/blob/master/2-final/src/utils/AuthContext.jsx

export interface AuthContextType {
  authState: AuthState;
  signedInUserLoadingState: SignedInUserLoadingState;
  getSignedInSentimentUser: () => SentimentUser | undefined;
  isSignedIn: () => boolean;
  isSigningIn: () => boolean;
  isSignedOut: () => boolean;
}

const AuthContext = createContext<AuthContextType>(
  {
    authState: AuthState.SIGNED_OUT,
    signedInUserLoadingState: SignedInUserLoadingState.NOT_LOADING,
    getSignedInSentimentUser: () => undefined,
    isSignedIn: () => false,
    isSigningIn: () => false,
    isSignedOut: () => false,
  } // <-- default value
);

const sentimentAuthInstance = SentimentAuth.Instance;

export const AuthProvider = ({children}: {
  children: any | undefined
}) => {

  // Used to force an immediate render update after sign-in/sign-out.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [_, forceUpdate] = useReducer(x => x + 1, 0);

  // For the Timer that reloads /me periodically.
  const [isActive] = useState(true);
  const [authState, setAuthState] = useState(AuthState.SIGNED_OUT);
  const [signedInSentimentUserState, setSignedInSentimentUserState] = useState(SignedInUserLoadingState.NOT_LOADING);

  useEffect(() => {
    // Remove all listeners in order to re-register below.
    sentimentAuthInstance.removeAllListeners();

    // Listen for and update state on AuthState changes...
    sentimentAuthInstance.onAuthStateChange(function (newAuthState) {
      setAuthState(newAuthState);
    });

    // Listen for and update state on SignedInSentimentUser changes...
    sentimentAuthInstance.onSignedInUserStateChange(
      function (newSignedInSentimentUserState: SignedInUserLoadingState) {
        setSignedInSentimentUserState(newSignedInSentimentUserState);
      });

  }, []); // <-- Only initialize this once.

  const contextData: AuthContextType = {
    authState: authState,
    signedInUserLoadingState: signedInSentimentUserState,
    getSignedInSentimentUser: () => sentimentAuthInstance.getSignedInSentimentUser(),
    isSignedIn: () => sentimentAuthInstance.isSignedIn(),
    isSigningIn: () => sentimentAuthInstance.isSigningIn(),
    isSignedOut: () => sentimentAuthInstance.isSignedOut(),
  };

  /**
   * Load the signed in {@link SentimentUser} from the /me endpoint.
   */
  useEffect(() => {
    // Load the /me user.
    sentimentAuthInstance.loadMe().then();

    ////////////
    // Periodically reload /me.
    // Until we have the SNT Amount in Firebase, load the /me user frequently in order to update the SNT amount.
    // At some point in the future, it would be good to have this information updated via WebSocket (i.e.,  sourced
    // from Firebase) so we don't have to do this polling on the API endpoint.
    ////////////
    let interval: NodeJS.Timeout | string | number | undefined = undefined;
    if (isActive) {
      interval = setInterval(async () => {
        return sentimentAuthInstance.loadMe().then();
      }, SentimentConfig.meReloadMs);
    } else if (!isActive) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, contextData.getSignedInSentimentUser()]);

  // Singleton Auth Object.
  // const loadingProcesses: LoadingProcess[] = [
  //   {
  //     name: "signed_in_sentiment_user",
  //     isReady: true
  //   },
  // ];

  return (
    // <AppLoader
    //   mandatoryProcesses={loadingProcesses}
    //   loadingComponent={<Splash/>}
    //   minimumLoadingTime={0} // <-- Load the app as soon as possible.
    // >
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
    // </AppLoader>
  );
}

// Export this to make using this component easier...
export const useAuth = () => {
  return useContext(AuthContext);
}

//export default AuthContext;