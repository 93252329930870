import {useNavigate} from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  HStack,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import React from "react";
import {SLASH_COMPOSE_SLASH_CLAIM} from "../../model/url_constants";
import {FcLike} from "react-icons/fc";

const WelcomeView = () => {

  // React State
  const navigate = useNavigate();

  return (
    <Center>
      <VStack
        w={{base: "375px", md: "640px", lg: "960px"}}
      >

        <Stack
          alignItems={"left"}
          p={"4"}
          m="4"
          boxShadow="lg"
          borderRadius="sm"
        >
          <Container maxW={"4xl"} alignItems={"left"}>
            <VStack alignItems={"left"}>
              <HStack as={"header"}>
                <Text
                  fontWeight={600}
                  fontSize={{base: "2xl", sm: "2xl", lg: "3xl"}}
                >Welcome to Sentiment!</Text>
                <FcLike size={"1.5rem"}/>
              </HStack>

              <Stack spacing={{base: 4, sm: 6}}
                     divider={
                       <StackDivider borderColor={useColorModeValue("gray.200", "gray.600")}/>
                     }>
                <VStack
                  spacing={{base: 4, sm: 6}}
                  alignItems={"left"}
                >
                  <Text fontSize={"lg"}>
                    Research shows that the quality of our decisions improves when we're more informed and more
                    aligned, so we built Sentiment to help with both.</Text>

                  <Text fontSize={"lg"}>Sentiment is built around user posts called
                    <Text fontWeight={"bold"} as={"span"}> Claims</Text>. Each Claim can be agreed or disagreed with,
                    and for deeper alignment, <Text fontWeight={"bold"} as={"span"}> Subclaims</Text> can be created to
                    explore the nuances of any particular viewpoint or opinion.
                  </Text>
                  <Text fontSize={"lg"}>
                    Get started in just three easy steps.
                  </Text>
                </VStack>
                <Box>
                  <Text
                    as={"span"}
                    color={useColorModeValue("blue.500", "blue.300")}
                    fontWeight={"700"}
                    textTransform={"uppercase"}
                    mb={"1"}
                  >
                    1. Organize.
                  </Text><Text as={"span"}> Start by making Claims and Subclaims that only you can see. This will help
                  organize your thoughts so you can confidently share your perspective.</Text>
                </Box>
                <Box>
                  <Text
                    as={"span"}
                    color={useColorModeValue("blue.500", "blue.300")}
                    fontWeight={"700"}
                    textTransform={"uppercase"}
                    mb={"1"}
                  >
                    2. Inform.
                  </Text>
                  <Text as={"span"}> Next, make a new claim from scratch with your best ideas and opinions from the step
                    above. Once you feel good about your Claims, share them with one or more people.
                  </Text>
                </Box>
                <Box>
                  <Text
                    as={"span"}
                    fontSize={"medium"}
                    color={useColorModeValue("blue.500", "blue.300")}
                    fontWeight={"700"}
                    textTransform={"uppercase"}
                    mb={"1"}
                  >
                    3. Decide.
                  </Text>
                  <Text as={"span"}> Once everyone has provided input -- either by agreeing, disagreeing, or creating
                    Subclaims -- make additional Subclaims that start with
                    <Text as={"span"} fontWeight={"semibold"}>"Decision Made"</Text> to capture what you've decided.
                    These can be used to remember your decisions in the future, when you or others may be fuzzy about
                    what was actually decided.
                  </Text>
                </Box>
                <HStack>
                  <Badge colorScheme="green">
                    Pro Tip
                  </Badge>
                  <Text fontSize={"sm"}><Text as={"span"} fontWeight={"semibold"}>Disagreement is Friendly. </Text>
                    When someone disagrees with a claim, they aren't communicating a permanent opinion; instead, these
                    agree/disagree choices may fluctuate over time. Consider disagreement as a form of alignment, and
                    not a form of opposition.
                  </Text>
                </HStack>
                <HStack>
                  <Badge colorScheme="green">
                    Pro Tip
                  </Badge>
                  <Text fontSize={"sm"}><Text as={"span"} fontWeight={"semibold"}>Avoid Questions.</Text> Sentiment
                    works best when anyone can either agree or disagree with a Claim. Try to form each Claim
                    as a statement instead of a question or a phrase.
                  </Text>
                </HStack>
                <Stack direction={{base: "column", md: "row"}}>
                  <Button colorScheme={"orange"} onClick={() => navigate(SLASH_COMPOSE_SLASH_CLAIM)}>
                    Make Your First Claim
                  </Button>
                </Stack>
              </Stack>
            </VStack>
          </Container>
        </Stack>

      </VStack>

    </Center>
  );
};

export default WelcomeView;