import {Claim} from "../../client/model";

/**
 * Compare function to sort subclaims by creation date according to the following rules:
 *
 * 1. Claims created most recently will display at the top of the list.
 * 2. Claims created least recently will display at the bottom of the list.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByCreationDateWithFallback = (a: Claim, b: Claim): number => {
  if (a.createdAt > b.createdAt) {
    return -1; // a is higher
  } else if (a.createdAt < b.createdAt) {
    return 1; // a is lower
  } else {
    // In general, we could return 0 here because most sorts above involve a creation_date. However, we do another
    // distance check here just in case someone calls this function directly.
    return sortSubClaimsByDistance(a, b);
  }
}

/**
 * Compare function for subclaims that orders according to the following rules:
 *
 * 1. Claims with more agree than disagree are ranked highest; In this subgroup, ties go to the claim with the most
 * `agree` votes;
 * 2. Neutral claims are ranked next highest; ties go to the newest claim;
 * 3. Claims with more disagree than agree are ranked lowest; In this subgroup, ties go to the claim with least
 * disagreement (i.e., the claim with the most agreement). Ties there go to the newest claim.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByAgreement = (a: Claim, b: Claim): number => {
  // Id | Creation | Agree | Disagree | Delta | Note
  // ===============================================
  // 0 | 1:00 | 50  | 0   | 50 | [Most agreement]
  // 1 | 1:01 | 400 | 399 | 1  |
  // 4 | 1:04 | 500 | 500 | 0  | [Least agreement]
  // 5 | 1:05 | 0   | 0   | 0  | [Most Neutral]
  // 2 | 1:02 | 399 | 400 | 1  | [Least Disagreement]
  // 3 | 1:03 | 0   | 50  | 50 | [Most Disagreement]

  if (isAgreeWinning(a) && isAgreeWinning(b)) {
    const distance = sortSubClaimsByDistance(a, b);
    return distance !== 0 ? distance : sortSubClaimsByCreationDate(a, b);
  } else if (isAgreeWinning(a) && !isAgreeWinning(b)) {
    return -1; // A wins.
  } else if (!isAgreeWinning(a) && isAgreeWinning(b)) {
    return 1; // B wins
  } else {
    // If we get here, both are either neutral or disagree-wins. Neutral always wins.
    if (isNeutral(a) && isNeutral(b)) {
      return sortSubClaimsByNumAgreeVotesWithFallback(a, b);
    } else if (isNeutral(a) && !isNeutral(b)) {
      return -1; // A wins, neutral is higher than not.
    } else if (!isNeutral(a) && isNeutral(b)) {
      return 1; // B wins, neutral is higher than not.
    } else {
      // If we get here, it means neither claims is neutral; so sort by disagreement, with least disagreement equating
      // to most agreement.
      const distance = sortSubClaimsByDistance(b, a);
      return distance !== 0 ? distance : sortSubClaimsByCreationDate(a, b);
    }
  }
}

/**
 * Compare function for subclaims that orders according to the following rules:
 *
 * 1. Claims with more disagree than agree are ranked highest; In this subgroup, ties go to the claim with the most
 * `disagree` votes;
 * 2. Neutral claims are ranked next highest; ties go to the newest claim;
 * 3. Claims with more agree than disagree are ranked lowest; In this subgroup, ties go to the claim with least
 * agreement (i.e., the claim with the most disagreement). Ties there go to the newest claim.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByDisagreement = (a: Claim, b: Claim): number => {
  // Id | Creation | Agree | Disagree | Delta | Note
  // ===============================================
  // 3 | 1:03 | 0   | 50  | 50 | [Most Disagreement]
  // 2 | 1:02 | 399 | 400 | 1  | [Less Disagreement]
  // 4 | 1:04 | 500 | 500 | 0  | [Least Disagreement]
  // 5 | 1:05 | 0   | 0   | 0  | [Most Neutral]
  // 1 | 1:01 | 400 | 399 | 1  | [Least Agreement]
  // 0 | 1:00 | 50  | 0   | 50 | [Most Agreement]

  if (isDisagreeWinning(a) && isDisagreeWinning(b)) {
    const distance = sortSubClaimsByDistance(a, b);
    return distance !== 0 ? distance : sortSubClaimsByCreationDate(a, b);
  } else if (isDisagreeWinning(a) && !isDisagreeWinning(b)) {
    return -1; // A wins.
  } else if (!isDisagreeWinning(a) && isDisagreeWinning(b)) {
    return 1; // B wins
  } else {
    // If we get here, both are either neutral or disagree-wins. Neutral always wins.
    if (isNeutral(a) && isNeutral(b)) {
      return sortSubClaimsByNumDisagreeVotesWithFallback(a, b);
    } else if (isNeutral(a) && !isNeutral(b)) {
      return -1; // A wins, neutral is higher than not.
    } else if (!isNeutral(a) && isNeutral(b)) {
      return 1; // B wins, neutral is higher than not.
    } else {
      // If we get here, it means neither claims is neutral; so sort by disagreement, with least disagreement equating
      // to most agreement. When those axes match, then sort by creation date.
      const distance = sortSubClaimsByDistance(b, a);
      return distance !== 0 ? distance : sortSubClaimsByCreationDate(a, b);
    }
  }
}

/**
 * Compare function for subclaims that orders according to the following rules:
 *
 * 1. If a Claim has more agree votes than another, it is ranked higher.
 * 2. When two claims have the same number of agree votes, the claim with the highest delta between agree and disagree
 * is ranked higher (on ground that a claim with _more_ agreement should rank higher).
 * 3. Claims with the largest number of neutral votes.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByNumAgreeVotesWithFallback = (a: Claim, b: Claim): number => {
  const aAgreeVotes = parseInt(a.claimMetrics.numAgreeVotes, 10);
  const bAgreeVotes = parseInt(b.claimMetrics.numAgreeVotes, 10);

  if (aAgreeVotes > bAgreeVotes) {
    return -1;
  } else if (aAgreeVotes < bAgreeVotes) {
    return 1;
  } else {
    // If we get here, it means both claims have the same number of agree votes, so fallback to preferencing the claim
    // with the greatest distance. If both claims have the same distance, fallback to sorting by creation date.
    const distance = sortSubClaimsByDistance(a, b);
    return distance !== 0 ? distance : sortSubClaimsByCreationDate(a, b);
  }
}

/**
 * Compare function for subclaims that orders according to the following rules:
 *
 * 1. If a Claim has more disagree votes than another, it is ranked higher.
 * 2. When two claims have the same number of disagree votes, the claim with the highest delta between agree and
 * disagree is ranked higher (on ground that a claim with _more_ disagreement should rank higher).
 * 3. Claims with the largest number of neutral votes.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByNumDisagreeVotesWithFallback = (a: Claim, b: Claim): number => {
  const aDisgreeVotes = parseInt(a.claimMetrics.numDisagreeVotes, 10);
  const bDisgreeVotes = parseInt(b.claimMetrics.numDisagreeVotes, 10);

  if (aDisgreeVotes > bDisgreeVotes) {
    return -1;
  } else if (aDisgreeVotes < bDisgreeVotes) {
    return 1;
  } else {
    // If we get here, it means both claims have the same number of disagree votes, so fallback to preferencing the
    // claim with the greatest distance. If both claims have the same distance, fallback to sorting by creation date.
    const distance = sortSubClaimsByDistance(a, b);
    return distance !== 0 ? distance : sortSubClaimsByCreationDate(a, b);
  }
}

/**
 * Compare function for subclaims that orders according to the following rules:
 *
 * 1. Claims with the largest number of disagree votes (largest disagreement)
 * 2. Claims with the largest number of agree votes.
 * 3. Claims with the largest number of neutral votes.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByNeutralityWithFallbacks = (a: Claim, b: Claim): number => {
  if (isNeutral(a) && !isNeutral(b)) {
    return -1;
  } else if (!isNeutral(a) && isNeutral(b)) {
    return 1;
  } else {
    const result = sortSubClaimsByDistance(a, b);
    return result === 0 ? sortSubClaimsByNumAgreeVotes(a, b) : result;
  }
}

////////////////
// Basic Sorters
////////////////

/**
 * Sort function for subclaims that orders claims with the latest `creation_date` first.
 *
 * 1. Claims created most recently will display at the top of the list.
 * 2. Claims created least recently will display at the bottom of the list.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByCreationDate = (a: Claim, b: Claim): number => {
  if (a.createdAt > b.createdAt) {
    return -1; // a is higher
  } else if (a.createdAt < b.createdAt) {
    return 1; // a is lower
  } else {
    return 0;
  }
}

/**
 * Sort function for subclaims that orders claims with the most `agree` votes first.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByNumAgreeVotes = (a: Claim, b: Claim): number => {
  const aAgreeVotes = parseInt(a.claimMetrics.numAgreeVotes, 10);
  const bAgreeVotes = parseInt(b.claimMetrics.numAgreeVotes, 10);

  if (aAgreeVotes > bAgreeVotes) {
    return -1;
  } else if (aAgreeVotes < bAgreeVotes) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Sort function for subclaims that orders claims with the most `disagree` votes first.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByNumDisagreeVotes = (a: Claim, b: Claim): number => {
  const aDisgreeVotes = parseInt(a.claimMetrics.numDisagreeVotes, 10);
  const bDisgreeVotes = parseInt(b.claimMetrics.numDisagreeVotes, 10);

  if (aDisgreeVotes > bDisgreeVotes) {
    return -1;
  } else if (aDisgreeVotes < bDisgreeVotes) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Compare function for subclaims that orders according to the following rules:
 *
 * 1. Claims with the largest number of disagree votes (largest disagreement)
 * 2. Claims with the largest number of agree votes.
 * 3. Claims with the largest number of neutral votes.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByNeutrality = (a: Claim, b: Claim): number => {
  if (isNeutral(a) && !isNeutral(b)) {
    return -1;
  } else if (!isNeutral(a) && isNeutral(b)) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Compare function for subclaims that orders according to the distance between two claims, with the claims having the
 * greatest distance between `agree` and `disagree` ranking above claims with smaller distance (distance is measured
 * as the number of votes between the `agree` and `disagree` votes. For example, if a claim has 10 `agree` votes and
 * `2` disagree votes, it will have a `distance` of 8.
 *
 * @param a A {@link Claim}.
 * @param b A {@link Claim}.
 */
export const sortSubClaimsByDistance = (a: Claim, b: Claim): number => {
  const aNumAgreeVotes = parseInt(a.claimMetrics.numAgreeVotes, 10);
  const bNumAgreeVotes = parseInt(b.claimMetrics.numAgreeVotes, 10);
  const aNumDisagreeVotes = parseInt(a.claimMetrics.numDisagreeVotes, 10);
  const bNumDisagreeVotes = parseInt(b.claimMetrics.numDisagreeVotes, 10);

  const aDistance: number = Math.abs(aNumAgreeVotes - aNumDisagreeVotes);
  const bDistance: number = Math.abs(bNumAgreeVotes - bNumDisagreeVotes);

  if (aDistance > bDistance) {
    return -1; // Greatest distance wins.
  } else if (aDistance < bDistance) {
    return 1; // Lower distance loses.
  } else {
    return 0; // Same distance sorts by creation date.
  }
}

/**
 * Determines if a claim has a neutral voting outcome (i.e., the number of 'agree' votes is the same as the number
 * of 'disagree' votes).
 * @param claim A {@link Claim}.
 * @return true if the claim is neutral; false otherwise.
 */
const isNeutral = (claim: Claim): boolean => {
  const numAgreeVotes = parseInt(claim.claimMetrics.numAgreeVotes, 10);
  const numDisagreeVotes = parseInt(claim.claimMetrics.numDisagreeVotes, 10);
  return numAgreeVotes == numDisagreeVotes;
}

/**
 * Determines if a claim has a neutral voting outcome (i.e., the number of 'agree' votes is the same as the number
 * of 'disagree' votes).
 * @param claim A {@link Claim}.
 * @return true if the claim is neutral; false otherwise.
 */
const isAgreeWinning = (claim: Claim): boolean => {
  const numAgreeVotes = parseInt(claim.claimMetrics.numAgreeVotes, 10);
  const numDisagreeVotes = parseInt(claim.claimMetrics.numDisagreeVotes, 10);
  return numAgreeVotes > numDisagreeVotes;
}

const isDisagreeWinning = (claim: Claim): boolean => {
  const numAgreeVotes = parseInt(claim.claimMetrics.numAgreeVotes, 10);
  const numDisagreeVotes = parseInt(claim.claimMetrics.numDisagreeVotes, 10);
  return numDisagreeVotes > numAgreeVotes;
}