// Contains business logic to render meta tags inside a React App.
// Note that this logic is distinct from the GCP Function that runs in front of Firebase hosting responses, but
// in the future these two sets of logic should likely be combined.

import React from "react";
import {Helmet} from "react-helmet";
import {ClaimWithIncludes} from "../../client/model/claim";
import {OpenGraph, populateOpenGraph} from "../../model/meta_tags";

interface MetaTagProps {
    claimWithIncludes: ClaimWithIncludes | undefined
}

export const MetaTags = (props: MetaTagProps): JSX.Element => {

   const og: OpenGraph = populateOpenGraph(props.claimWithIncludes);

    return (
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{og.title}</title>
            <link rel="canonical" href={og.url}/>

            <meta property="og:type" content={og.type}/>
            <meta property="og:locale" content={og.locale}/>
            <meta property="og:title" content={og.title}/>
            <meta property="og:description" content={og.description}/>
            <meta property="og:image" content={og.imageUrl}/>
            <meta property="og:url" content={og.url}/>
            <meta property="og:site_name" content={og.siteName}/>

            {/* twitter card */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@sentiment_fyi"/>
            <meta name="twitter:creator" content="@sentiment_fyi"/>

            {/* Slack & Share Sheets */}
            <meta name={"twitter:label1"} content="Created by"/>
            <meta name="twitter:data1" content={og.claimAuthorName ?? ""}/>

            <meta name="twitter:label2" content="# Subclaims"/>
            <meta name="twitter:data2" content={og.numSubclaims + "" ?? ""}/>

            <meta name="twitter:label3" content="Agreement"/>
            {/*<meta name="twitter:data3" content="74% Agreeing" />*/}
            <meta name="twitter:data3" content={og.agreement ?? ""}/>
        </Helmet>
    );

}