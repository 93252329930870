import {Container, Heading, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr,} from "@chakra-ui/react";
import React from "react";

export const Env = () => {
  return <Container
    minH={"full"}
    maxW={"5xl"}
  >
    <Stack textAlign={"center"}>
      <Heading my={"1em"}>
        Sentiment Environment Variables
      </Heading>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Environment Variable</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td fontWeight={"bold"}>NODE_ENV</Td>
              <Td>{process.env.NODE_ENV}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>REACT_APP_DOMAIN</Td>
              <Td>{process.env.REACT_APP_DOMAIN}</Td>
            </Tr>
            <Tr>
              <Td bgColor={"gray.200"}>REACT_APP_</Td>
              <Td bgColor={"gray.200"}></Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>PUBLIC_API_URL</Td>
              <Td>{process.env.REACT_APP_PUBLIC_API_URL}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>FIREBASE_AUTH_EMULATOR_HOST</Td>
              <Td>{process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST}</Td>
            </Tr>
            <Tr>
              <Td bgColor={"gray.200"}>(Firebase) REACT_APP_</Td>
              <Td bgColor={"gray.200"}></Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>FIREBASE_API_KEY</Td>
              <Td>{process.env.REACT_APP_FIREBASE_API_KEY}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>AUTH_DOMAIN</Td>
              <Td>{process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>PROJECT_ID</Td>
              <Td>{process.env.REACT_APP_FIREBASE_PROJECT_ID}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>STORAGE_BUCKET</Td>
              <Td>{process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>MESSAGE_SENDER_ID</Td>
              <Td>{process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>APP_ID</Td>
              <Td>{process.env.REACT_APP_FIREBASE_APP_ID}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>MEASUREMENT_ID</Td>
              <Td>{process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}</Td>
            </Tr>
            <Tr>
              <Td bgColor={"gray.200"}>(Stripe) REACT_APP_</Td>
              <Td bgColor={"gray.200"}></Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>STRIPE_PRICING_TABLE_SCRIPT_URL</Td>
              <Td>{process.env.REACT_APP_STRIPE_PRICING_TABLE_SCRIPT_URL}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>STRIPE_PRICING_TABLE_ID</Td>
              <Td>{process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>STRIPE_PUBLISHABLE_KEY</Td>
              <Td>{process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}</Td>
            </Tr>
            <Tr>
              <Td fontWeight={"bold"}>STRIPE_CUSTOMER_PORTAL_URL</Td>
              <Td>{process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  </Container>
}