import React, {ReactNode} from "react";
import {Link as RouterLink} from "react-router-dom";
import {
  Box,
  Button,
  chakra,
  Collapse,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Link as ChakraLink,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  VisuallyHidden
} from "@chakra-ui/react";
import {ChevronDownIcon, CloseIcon, HamburgerIcon, MoonIcon, SunIcon,} from "@chakra-ui/icons";
import {FaLinkedin, FaTwitter, FaYoutube} from "react-icons/fa";
import {MdFeedback} from "react-icons/md";
import {IoMdMail} from "react-icons/io";

const Footer = () => {
  return (
    <Box>
      <DesktopFooter/>
      <MobileFooter/>
    </Box>
  );
}

const SocialButton = ({
                        children,
                        label,
                        href,
                      }: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

// Desktop Nav
const DesktopFooter = () => {
  const currentYear = new Date().getFullYear();
  const {colorMode, toggleColorMode} = useColorMode();
  return (<Box
    bg={useColorModeValue("white", "gray.900")}
    color={useColorModeValue("gray.700", "gray.200")}
    position={"absolute"}
    width={"100%"}
    bottom={"0"}
    display={{base: "none", md: "block", lg: "block"}}
  >
    <HStack
      // minW={"6xl"}
      py={"0.75rem"}
      px={"2rem"}
      // direction={{base: "column", md: "row"}}
      // spacing={4}
      justify={{base: "center", md: "space-between"}}
      align={{base: "center", md: "center"}}
      borderTop={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
    >
      <Text fontSize={"sm"}>© {currentYear} Sentiment Labs | All rights reserved</Text>
      <Stack direction={"row"} spacing={6}>
        <HStack>
          <ChakraLink fontSize={"sm"} href={"https://legal.sentiment.fyi/privacy.html"} target="_blank"
                      rel="noopener noreferrer">Privacy Policy</ChakraLink>
          <Text>|</Text>
          <ChakraLink fontSize={"sm"} href={"https://legal.sentiment.fyi/tos.html"} target="_blank"
                      rel="noopener noreferrer">Terms of Service</ChakraLink>
        </HStack>
        <SocialButton label={"Twitter"} href={"https://twitter.com/sentiment_fyi"}>
          <FaTwitter/>
        </SocialButton>
        <SocialButton label={"YouTube"} href={"https://www.youtube.com/@Sentiment_fyi"}>
          <FaYoutube/>
        </SocialButton>
        <SocialButton label={"LinkedIn"} href={"https://www.linkedin.com/company/sentimentlabs/"}>
          <FaLinkedin/>
        </SocialButton>
        <SocialButton label={"Contact Us"} href={"mailto:help@sentiment.fyi"}>
          <IoMdMail/>
        </SocialButton>
        <SocialButton label={"Contact Us"} href={"https://sentiment.fyi/claims/BRlsVhJgMQYaihs9raa1"}>
          <MdFeedback/>
        </SocialButton>
        <Button onClick={toggleColorMode}
                w={8}
                h={8}
        >
          {colorMode === "light" ? <MoonIcon/> : <SunIcon/>}
        </Button>
      </Stack>
    </HStack>
  </Box>);
}

// const DesktopFooterNav = () => {
//   const linkColor = useColorModeValue("gray.600", "gray.200");
//   const linkHoverColor = useColorModeValue("orange.400", "orange");
//
//   return (
//     <Stack
//       direction={"row"}
//       spacing={1}
//     >
//       {NAV_ITEMS.map((navItem, index) => (
//         <Box
//           _hover={{
//             textDecoration: "none",
//             color: linkHoverColor,
//           }}
//           key={index}
//         >
//           <RouterLink
//             id={"withSubNavigation"}
//             to={navItem.href ?? SLASH + ""}
//           >
//             <Text margin={"0"}
//                   p={2}
//                   fontSize={"sm"}
//                   fontWeight={500}
//                   color={linkColor}
//                   _hover={{
//                     textDecoration: "none",
//                     color: linkHoverColor,
//                   }}
//             >
//               {index === 0 && <Box as={"span"} color={linkHoverColor}>{navItem.label}</Box>}
//               {index !== 0 && navItem.label}
//             </Text>
//           </RouterLink>
//         </Box>
//       ))}
//     </Stack>
//   );
// };

// Mobile Nav

const MobileFooter = () => {
  const {isOpen, onToggle} = useDisclosure();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      position={"absolute"}
      // zIndex={10}
      bottom={"0"}
    >
      <Flex
        padding={"0.5rem"}
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        display={{base: "flex", md: "none", lg: "none"}}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
      >
        <Grid
          templateColumns="repeat(3, 1fr)" gap={0}
          width={"full"}
        >
          <GridItem>
            <Flex>
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ? <CloseIcon w={3} h={3}/> : <HamburgerIcon w={5} h={5}/>
                }
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
              />
            </Flex>
          </GridItem>

          <GridItem width={"max"}>
            <Container centerContent paddingTop={"0.7em"}>
              <Text fontSize={"xs"}>© {currentYear} Sentiment Labs | All rights reserved</Text>
            </Container>
          </GridItem>
        </Grid>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileFooterNav/>
      </Collapse>
    </Box>
  );
}

const MobileFooterNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{md: "none", lg: "none"}}
      borderBottom="1px"
      borderColor={"gray.200"}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileFooterNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  isHrefExternal: boolean;
}

const MobileFooterNavItem = ({label, children, href}: NavItem) => {
  const {isOpen, onToggle} = useDisclosure();
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        // borderBottom="1px" borderColor={"gray.100"}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}>
          <RouterLink to={href ?? "/"}>
            {label}
          </RouterLink>
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{marginTop: "0!important"}}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}>
          {children &&
            children.map((child) => {
              return (
                <RouterLink key={child.label} to={{pathname: child.href || "/"}} target="_blank">
                  {child.label}
                </RouterLink>
              );
            })
          }
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Legal",
    href: "#",
    isHrefExternal: false,
    children: [
      {
        label: "Terms of Service",
        // subLabel: "Trending Design to inspire you",
        href: "https://legal.sentiment.fyi/tos.html",
        isHrefExternal: true,
      },
      {
        label: "Privacy",
        // subLabel: "Up-and-coming Designers",
        href: "https://legal.sentiment.fyi/privacy.html",
        isHrefExternal: true,
      },
    ],
  },
  // {
  //   label: "Top Claims",
  //   href: "#"
  // },
];

export default Footer;