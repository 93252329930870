import {ClaimType} from "./create_claim";


export function constructClaimButtonText(claimType: ClaimType): string {
  switch (claimType) {
    case ClaimType.SubClaim:
      return "Make Subclaim";
    case ClaimType.Claim:
    case ClaimType.ParentClaim:
    case ClaimType.SummaryClaim:
    default: {
      return "Make Claim";
    }
  }
}

export function constructClaimsNotEmptyErrorDescription(claimType: ClaimType): string {
  switch (claimType) {
    case ClaimType.SubClaim:
      return "Subclaims can't be empty";
    case ClaimType.Claim:
    case ClaimType.ParentClaim:
    case ClaimType.SummaryClaim:
    default: {
      return "Claims can't be empty";
    }
  }
}