import axios from "axios";
import {firebaseAuth} from "./firebase_auth";

//////////////
// Centralizes all axios configuration
//////////////
const sentimentAxios = axios;

sentimentAxios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API_URL;
sentimentAxios.defaults.headers.post["Accept"] = "application/json";
sentimentAxios.defaults.headers.post["Content-Type"] = "application/json";

// Request interceptor
sentimentAxios.interceptors.request.use(async config => {
    // Assign the Firebase Auth JWT (or undefined if there isn't one).
    await firebaseAuth.currentUser?.getIdToken().then(jwt => {
      if (jwt.length > 0) {
        config.headers.Authorization = "Bearer " + jwt;
      } else {
        config.headers.Authorization = undefined;
      }
    });
    return config
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

export default sentimentAxios;