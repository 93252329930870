import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Image,
  SkeletonCircle,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {ChevronDownIcon, CloseIcon, HamburgerIcon,} from "@chakra-ui/icons";
import {SLASH,} from "../../model/constants";
import {
  SLASH_COMPOSE_SLASH_CLAIM,
  SLASH_HOW_IT_WORKS,
  SLASH_RECENT_CLAIMS,
  SLASH_SIGN_IN
} from "../../model/url_constants";
import {SignedInUserDrawer} from "./SignedInUserDrawer/SignedInUserDrawer";
import {SentimentUser} from "../../client/model";
import {prevLocationTracker} from "../../utils/location_utils";

interface NavBarProps {
  isSigningIn: boolean;
  // if this is undefined, we consider the user to not be signed in.
  signedInSentimentUser?: SentimentUser;
}

export const NavBar = (props: NavBarProps) => {

  return (
    <Box>
      <DesktopNavBar {...props}/>
      <MobileNavBar {...props}/>
    </Box>
  );
}

// Desktop Nav
const DesktopNavBar = (props: NavBarProps) => {

  return (<Flex
    bg={useColorModeValue("white", "gray.800")}
    color={useColorModeValue("gray.600", "white")}
    minH={"60px"}
    display={{base: "none", md: "flex", lg: "flex"}}
    borderBottom={1}
    borderStyle={"solid"}
    borderColor={useColorModeValue("gray.200", "gray.900")}
  >
    <HStack
      width={"full"}
    >
      <Center className={"logo"}
              width={"3rem"}
              minH={"40px"}
              marginLeft={"1rem"}
      >
        {/* Only show this when a user is signed in */}
        {props.signedInSentimentUser &&
          <Link to={SLASH}>
            <Image
              height={"40px"}
              src={"/icons/logo192ot.png"}/>
          </Link>}

        {/* Nothing is shown in the nav-bar during interstitials */}
        {/* TODO: Show loading imagery here */}

        {/* Only show this when a user is signed out */}
        {!props.signedInSentimentUser &&
          <Link to={SLASH}>
            <Image
              height={"40px"}
              src={"/icons/logo192ot.png"}/>
          </Link>}
      </Center>
      <Box width={"full"} className={"desktopNavHStack"}>
        <DesktopNav {...props}/>
      </Box>
      <Box>
        <SignedInUser {...props}/>
      </Box>
    </HStack>
  </Flex>);
}

const DesktopNav = (props: NavBarProps) => {

  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("orange.400", "orange");

  const navItems = props.signedInSentimentUser ? SIGNED_IN_NAV_ITEMS : SIGNED_OUT_NAV_ITEMS;

  return (
    <Stack
      direction={"row"}
      spacing={1}
    >
      {navItems.map((navItem, index) => (
        <Box
          _hover={{
            textDecoration: "none",
            color: linkHoverColor,
          }}
          key={index}
        >
          <Link
            id={"withSubNavigation"}
            to={navItem.href ?? SLASH + ""}>
            <Text margin={"0"}
                  p={2}
                  fontSize={"sm"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
            >
              {index === 0 && <Box as={"span"} color={linkHoverColor}>{navItem.label}</Box>}
              {index !== 0 && navItem.label}
            </Text>
          </Link>
        </Box>
      ))}
    </Stack>
  );
};

// Mobile Nav

const MobileNavBar = (props: NavBarProps) => {
  const {isOpen, onToggle} = useDisclosure();

  return (
    <Box>
      <Flex
        padding={"0.5rem"}
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        display={{base: "flex", md: "none", lg: "none"}}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
      >
        <Grid
          templateColumns='repeat(3, 1fr)' gap={0}
          width={"full"}
        >
          <GridItem>
            <Flex>
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ? <CloseIcon w={3} h={3}/> : <HamburgerIcon w={5} h={5}/>
                }
                variant={"ghost"}
                aria-label={"Toggle Navigation"}
              />
            </Flex>
          </GridItem>
          <GridItem
            minW={"40px"}
          >
            <Center>
              {/*
                Clicking the Sentiment Icon should always take a user to the sentiment.fyi home page at
                https://sentiment.fyi
              */}
              <Link to={SLASH}>
                <Image
                  height={"40px"}
                  src={"/icons/logo192ot.png"}/>
              </Link>
            </Center>
          </GridItem>
          <GridItem>
            <SignedInUser {...props}/>
          </GridItem>
        </Grid>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav {...props} />
      </Collapse>
    </Box>
  );
}

const MobileNav = (props: NavBarProps) => {

  // Only show the nav items if a user is fully signed in (i.e., FirebaseAuth & SentimentAuth have returned).
  const navItems = props.signedInSentimentUser ? SIGNED_IN_NAV_ITEMS : SIGNED_OUT_NAV_ITEMS;

  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{md: "none", lg: "none"}}
      borderBottom="1px"
      borderColor={"gray.200"}
    >
      {navItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const MobileNavItem = ({label, children, href}: NavItem) => {
  const {isOpen, onToggle} = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        // borderBottom="1px" borderColor={"gray.100"}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}>
          <Link to={href ?? "/"}>
            {label}
          </Link>
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      {/*<Collapse in={isOpen} animateOpacity style={{marginTop: '0!important'}}>*/}
      {/*    <Stack*/}
      {/*        mt={2}*/}
      {/*        pl={4}*/}
      {/*        borderLeft={1}*/}
      {/*        borderStyle={'solid'}*/}
      {/*        borderColor={useColorModeValue('gray.200', 'gray.700')}*/}
      {/*        align={'start'}>*/}
      {/*        {children &&*/}
      {/*            children.map((child) => (*/}
      {/*                <ChakraLink key={child.label} py={2} href={child.href}>*/}
      {/*                    {child.label}*/}
      {/*                </ChakraLink>*/}
      {/*            ))}*/}
      {/*    </Stack>*/}
      {/*</Collapse>*/}
    </Stack>
  );
};

const SIGNED_IN_NAV_ITEMS: Array<NavItem> = [
  {
    label: "Make a Claim",
    href: SLASH_COMPOSE_SLASH_CLAIM,
    // children: [
    //     {
    //         label: 'Explore Design Work',
    //         subLabel: 'Trending Design to inspire you',
    //         href: '#',
    //     },
    //     {
    //         label: 'New & Noteworthy',
    //         subLabel: 'Up-and-coming Designers',
    //         href: '#',
    //     },
    // ],
  },
  {
    label: "Recent Claims",
    href: SLASH_RECENT_CLAIMS,
  },
  {
    label: "How it Works",
    href: SLASH_HOW_IT_WORKS,
  },
];

const SIGNED_OUT_NAV_ITEMS: Array<NavItem> = [
  {
    label: "Make a Claim",
    href: SLASH_COMPOSE_SLASH_CLAIM,
  },
  {
    label: "How it Works",
    href: SLASH_HOW_IT_WORKS,
  },
];

const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Button
      as={"a"}
      fontSize={"sm"}
      fontWeight={400}
      variant={"link"}
      onClick={() => {
        // Set the location's path (if any)s
        const from = location.pathname;
        if (from) {
          prevLocationTracker.setPreviousLocationPath(from);
        }

        navigate(SLASH_SIGN_IN,
          {
            replace: true,
            state: {from: location}
          });
      }}>
      Sign In
    </Button>
  );
};

const SignedInUser = (props: NavBarProps) => {

  return (
    <Flex
      className={"SignedInUserFlex"}
      justifyContent={"flex-end"}
      height={"100%"}
      paddingRight={"1rem"}
    >
      {
        props.isSigningIn ? (<SkeletonCircle size='32px'/>) : (
          props.signedInSentimentUser ?
            (
              <SignedInUserDrawer signedInSentimentUser={props.signedInSentimentUser}/>
            )
            : (
              <SignIn/>
            )
        )
      }
    </Flex>);
}