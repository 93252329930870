import {useEffect, useRef, useState} from "react";
import {auth} from "firebaseui";

// See https://github.com/firebase/firebaseui-web-react/pull/173#issuecomment-1151532176
import "firebaseui/dist/firebaseui.css";
import {onAuthStateChanged} from "firebase/auth";

interface Props {
  // The Firebase UI Web UI Config object.
  // See: https://github.com/firebase/firebaseui-web#configuration
  uiConfig: auth.Config;
  // Callback that will be passed the FirebaseUi instance before it is
  // started. This allows access to certain configuration options such as
  // disableAutoSignIn().
  uiCallback?(ui: auth.AuthUI): void;

  // The Firebase App auth instance to use.
  firebaseAuth: any; // As firebaseui-web
  className?: string;
}

// See https://firebase.google.com/docs/auth/web/firebaseui
// WARNING: Warning: FirebaseUI is not currently compatible with the v9 modular SDK. The v9 compatibility layer
// (specifically, the app-compat and auth-compat packages) permits the usage of FirebaseUI alongside v9, but without
// the app size reduction and other benefits of the v9 SDK.

const StyledFirebaseAuth = ({uiConfig, firebaseAuth, className, uiCallback}: Props) => {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    // Get or Create a firebaseUI instance.
    const ui:auth.AuthUI = auth.AuthUI.getInstance() || new auth.AuthUI(firebaseAuth);
    if (uiConfig.signInFlow === "popup") {
      ui.reset();
    }

    // We track the auth state to reset firebaseUi if the user signs out.

    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && userSignedIn)
        ui.reset();
      setUserSignedIn(!!user);
    });

    // Trigger the callback if any was set.
    if (uiCallback) {
      uiCallback(ui);
    }

    // Render the firebaseUi Widget.
    ui.start(elementRef.current || "", uiConfig);

    return () => {
      unregisterAuthObserver();
      ui.reset();
    };
  }, [auth, uiConfig]);

  return <div className={className} ref={elementRef}/>;
};

export default StyledFirebaseAuth;