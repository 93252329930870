import React from "react";
import {Alert, AlertDescription, AlertIcon, AlertTitle, Center, VStack} from "@chakra-ui/react";

const PageNotFound = () => {
  return <Center alignContent={"center"}>
    <VStack width={"100%"}>
      <Alert
        status='info'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='250px'
      >
        <AlertIcon boxSize='40px' mr={0}/>
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          Houston, we have a problem.
        </AlertTitle>
        <AlertDescription maxWidth='lg'>
          <br/><b>Claim</b>: What you are looking for does not exist (404)
        </AlertDescription>
      </Alert>
    </VStack>
  </Center>
}

export default PageNotFound;