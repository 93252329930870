import "./App.css";
import React from "react";
import {BrowserRouter} from "react-router-dom";
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import {mode} from "@chakra-ui/theme-tools"
import type {StyleFunctionProps} from "@chakra-ui/styled-system"
import {SentimentRoutes} from "./routes_firebase";
import {AuthProvider} from "./components/auth/AuthContext";

const App = () => {

  // 2. Call `extendTheme` and pass your custom values
  const overrides = extendTheme({
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          // fontFamily: 'body',
          // color: mode('gray.800', 'whiteAlpha.900')(props),
          bg: mode("gray.50", "gray.800")(props),
          // lineHeight: 'base',
        },
      }),
    },
  })

  return (
    <React.StrictMode>
      <ChakraProvider theme={overrides}>
        <BrowserRouter>
          <AuthProvider>
            <SentimentRoutes/>
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </React.StrictMode>
  );
}

export default App;