// Summary: This file contains fully-qualified URL constants
import {
  ADMIN,
  CLAIM,
  CLAIMS,
  COLON_ID,
  COMPOSE,
  CONTEST_SELECTIONS,
  ERROR,
  HOME,
  HOW_IT_WORKS,
  ME,
  RECENT_CLAIMS,
  SIGN_IN,
  SLASH,
  SUBSCRIBE,
  USERS,
} from "./constants";

export const ENV = "env";
export const FUNDING_REQUESTS = "funding-requests";

// SLASH PREFIXES

export const SLASH_ADMIN = SLASH + ADMIN;
export const SLASH_SUBSCRIBE: string = SLASH + SUBSCRIBE;
export const SLASH_CLAIMS: string = SLASH + CLAIMS;
export const SLASH_CONTEST_SELECTIONS: string = SLASH + CONTEST_SELECTIONS;
export const SLASH_ERROR: string = SLASH + ERROR;
export const SLASH_HOME: string = SLASH + HOME;
export const SLASH_ME: string = SLASH + ME;
export const SLASH_RECENT_CLAIMS: string = SLASH + RECENT_CLAIMS;
export const SLASH_HOW_IT_WORKS: string = SLASH + HOW_IT_WORKS;
export const SLASH_USERS: string = SLASH + USERS;
export const SLASH_SIGN_IN: string = SLASH + SIGN_IN;
// export const SLASH_SEARCH: string = SLASH + SEARCH;

// ///////
// /admin
// ///////
export const SLASH_ADMIN_SLASH_ENV: string = SLASH_ADMIN + SLASH + ENV;
export const SLASH_ADMIN_SLASH_FUNDING_REQUESTS: string = SLASH_ADMIN + SLASH + FUNDING_REQUESTS;
// export const SLASH_ADMIN_SLASH_SEARCH: string = SLASH_ADMIN + SLASH + SEARCH;

// ///////
// https://api.sentiment.fyi/{path}
// ///////

export const API_URL_SLASH_ADMIN = process.env.REACT_APP_PUBLIC_API_URL + SLASH_ADMIN;
export const API_URL_SLASH_ME = process.env.REACT_APP_PUBLIC_API_URL + SLASH_ME;
export const API_URL_SLASH_USERS = process.env.REACT_APP_PUBLIC_API_URL + SLASH_USERS;
export const API_URL_SLASH_CLAIMS = process.env.REACT_APP_PUBLIC_API_URL + SLASH_CLAIMS;

// ///////
// Parameterized URLs
// ///////

export const SLASH_CLAIMS_SLASH_COLON_ID: string = SLASH + CLAIMS + SLASH + COLON_ID;
// export const SLASH_CLAIMS_SLASH_COLON_ID_SLASH_COLON_TEXT: string = SLASH_CLAIMS_SLASH_COLON_ID + SLASH + COLON_TEXT;
export const SLASH_COMPOSE_SLASH_CLAIM: string = SLASH + COMPOSE + SLASH + CLAIM;

// const ATTACHMENTS_DOT_CONTEST_IDS: string = ATTACHMENTS + DOT + CONTEST_IDS;
// const FULL_CLAIM_EXPANSION = AUTHOR_ID;
// export const fetchClaimUrl = (claimId: string) =>
//   API_URL_SLASH_CLAIMS + SLASH + claimId + "?" + EXPANSIONS + "=" + FULL_CLAIM_EXPANSION;
export const fetchVoteUrl = (signedInUserId: string, claimId: string) =>
  API_URL_SLASH_USERS + SLASH + signedInUserId + SLASH_CONTEST_SELECTIONS + SLASH + claimId;

export const fetchClaimContestSelectionsUrl = (userId: string) =>
  API_URL_SLASH_USERS + SLASH + userId + SLASH + CONTEST_SELECTIONS;

export const fetchRecentlyViewedClaimsUrl = (userId: string) =>
  API_URL_SLASH_USERS + SLASH + userId + SLASH + RECENT_CLAIMS;

export const fundUserUrl = () => API_URL_SLASH_ADMIN + SLASH + FUNDING_REQUESTS;

// Error URLs
// export const ERRORS_URL = "https://errors.sentiment.fyi";
// export const ERRORS_URL_NOT_FOUND = "https://errors.sentiment.fyi/not-found";
