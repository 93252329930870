import React from "react";
import {MdThumbDown, MdThumbUp} from "react-icons/md"
import {ClaimContestOption, ClaimContestSelection, ClaimMetrics} from "../../../client/model";
import {Box, Button, HStack,} from "@chakra-ui/react"

// let formatter: Intl.NumberFormat
//     = Intl.NumberFormat('en', {notation: 'compact', compactDisplay: 'short'});

export interface VoteButtonProps {
  onClick(contestOption: ClaimContestOption): void;

  votingEnabled: boolean,
  isVotingInProgress: boolean,
  buttonType: ClaimContestOption,
  signedInUserContestSelection?: ClaimContestSelection, // Current user's selection
  claimMetrics?: ClaimMetrics,
  buttonSize: string,
}

const VoteButton = (props: VoteButtonProps) => {

  const isUserVoting = (): boolean => {
    return props.signedInUserContestSelection?.selectedOption === props.buttonType
      && (+props.signedInUserContestSelection?.numVotes ?? 0) > 0;
  };

  const claimMetrics: ClaimMetrics | undefined = props.claimMetrics;

  let label;
  // let icon_color;
  // let isButtonActive: boolean;
  let leftIcon;
  let formattedNumVotes;
  let colorScheme;
  const fontSize = props.buttonSize === "small" ? "x-small" : "";
  const size = props.buttonSize === "small" ? "xs" : "lg";

  if (props.buttonType === ClaimContestOption.AGREE) {
    label = "Agree";
    colorScheme = "blue";
    // icon_color = currentUserVoteInfo.isAgreeWinning() ? 'chakra-pink-600' : 'chakra-pink-600';
    leftIcon = <MdThumbUp
      // style={{color: icon_color}}
    />;
    // formattedNumVotes = "(" + formatter.format(parseInt(claimMetrics?.numAgreeVotes ?? "0")) + ")"
    formattedNumVotes = claimMetrics?.formattedNumAgreeVotes ?? "0";
  } else {
    label = "Disagree";
    colorScheme = "pink";
    // icon_color = currentUserVoteInfo.isDisagreeWinning() ? "#008c3d" : "#D64161";
    leftIcon = <MdThumbDown
      // style={{color: icon_color}}
    />;
    // formattedNumVotes = "(" + formatter.format(parseInt(claimMetrics?.numDisagreeVotes ?? "0")) + ")";
    formattedNumVotes = claimMetrics?.formattedNumDisagreeVotes ?? "0";
  }

  return (
    <Button
      id={"disagree_button"}
      size={size}
      fontSize={fontSize}
      variant='outline'
      isActive={isUserVoting()}
      leftIcon={leftIcon}
      colorScheme={colorScheme}
      padding='1.5em'
      onClick={() => props.onClick(props.buttonType)}
      isDisabled={!props.votingEnabled}
      isLoading={props.isVotingInProgress}
      loadingText='Voting...'
    >
      <HStack>
        <Box>
          {label}
        </Box>
        <Box>
          {formattedNumVotes}
        </Box>
      </HStack>
    </Button>
  );
}

export {ClaimContestOption, VoteButton}