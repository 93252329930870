import React, {Fragment, useEffect, useState} from "react";
import {Link as ReactRouterLink, useParams} from "react-router-dom";
import {Claim, ClaimContestSelection, SentimentUser} from "../../client/model";
import {VoteButtonsContainer} from "../../components/buttons/VoteButtons";
import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  List,
  ListItem,
  Select,
  SkeletonCircle,
  Spacer,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import {firebaseApp} from "../../utils/firebase"
import CreateSubClaim from "../../components/CreateClaim/create_sub_claim";
import sentimentAxios from "../../utils/axios";
import {fetchClaimContestSelectionsUrl, fetchRecentlyViewedClaimsUrl} from "../../model/url_constants";
import {ClaimWithIncludes} from "../../client/model/claim";
import {SubclaimVoteButtonsContainer} from "../../components/buttons/VoteButtons/SubclaimVoteButtonsContainer";
import ClaimNotFound from "./claim_not_found";
import {ChevronRightIcon} from "@chakra-ui/icons";
import {findContestSelectionByClaimId, updateContestMetricsStateOnVoteClick} from "./claim_view_utils";
import {
  computeDateDisplayString,
  computeDateDisplayStringFromClaim,
  computeNumSubClaimsMessage,
  getAuthorUser,
  getAuthorUserUsername,
  getUserProfileImageUrl
} from "../ViewUtils/common_claim_view_utils";
import {MetaTags} from "./meta_tags_view";
import ReactMarkdown from "react-markdown";
import {toString} from "hast-util-to-string";
import {Nodes} from "hast-util-to-string/lib";
import {
  sortSubClaimsByAgreement,
  sortSubClaimsByCreationDateWithFallback,
  sortSubClaimsByDisagreement,
  sortSubClaimsByNeutralityWithFallbacks,
  sortSubClaimsByNumAgreeVotesWithFallback,
  sortSubClaimsByNumDisagreeVotesWithFallback,
} from "./subclaim_sorting";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";
import {useAuth} from "../../components/auth/AuthContext";

const byCreationDate = "1";
const byAgreement = "2";
const byDisagreement = "3";
const byNeutrality = "4";
const byNumAgreeVotes = "5";
const byNumDisagreeVotes = "6";

const ClaimViewFirestore = () => {

  // This hook provides access to a variety of contextual properties, most importantly the `signedInSentimentUser`.
  // Because this is contextual and wraps this component via AuthProvider at the routing level in App.tsx, whenever
  // state changes in the AuthProvider (e.g., /me returns valid user details, thus populating `signedInSentimentUser`),
  // this change will trigger a re-render in the AuthProvider, which will re-render this page.
  const {isSignedIn, getSignedInSentimentUser} = useAuth();
  const claimId = useParams().id || "";

  // React State
  const [fetchingClaimInfo, setFetchingClaimInfo] = useState<boolean>(false);
  const [fetchingClaimContestSelections, setFetchingClaimContestSelections] = useState<boolean>(false);
  const [claimWithIncludes, setClaimWithIncludes] = useState<ClaimWithIncludes>();
  const [claimContestSelections, setClaimContestSelections] = useState<ClaimContestSelection[]>([])
  // Used to indicate the sort method for subclaims. The empty string (the default) will sort by creationDate.
  const [subClaimSortBy, setSubClaimSortBy] = useState<string>(byCreationDate);

  // Effects
  /**
   * Fetch a new claim from Firestore whenever the claimId changes. This is required to show new claim data whenever
   * the user navigates to the page. This also loads the claim the first time when a page loads (e.g.,
   * direct-linking).
   */
  useEffect(() => {
    console.debug("setFetchingClaimInfo=%s !", true);
    setFetchingClaimInfo(true);

    const firestore = getFirestore(firebaseApp);
    const docRef = doc(firestore, "claims/", claimId);

    const unsubscribe = onSnapshot(docRef, (documentSnapshot) => {
        console.debug("setFetchingClaimInfo=%s !", fetchingClaimInfo);
        if (documentSnapshot.exists()) {
          const claimWithIncludes: ClaimWithIncludes = documentSnapshot.data() as ClaimWithIncludes;
          claimWithIncludes.data.id = claimId;
          setClaimWithIncludes(claimWithIncludes);
        } else {
          // NOTE: doc.data() will be undefined in this case
          console.warn("No such FirestoreClaim document with id=%s !", claimId);
          setClaimWithIncludes(undefined);
        }
        setFetchingClaimInfo(false); // <-- Stop showing loading UI
      },
      error => {
        console.debug("Error getting FirestoreClaim with Id={}:", claimId, error);
        setClaimWithIncludes(undefined);
        // TODO: Show Error
        setFetchingClaimInfo(false); // <-- Stop showing loading UI
      }
    );

    return () => {
      console.debug("Detaching Document listener for claimId=" + claimId);
      unsubscribe();
    };

  }, [claimId]); // <-- Reload whenever the ClaimId changes.

  /**
   * Tell Sentiment that this user viewed a Claim.
   */
  useEffect(() => {
      updateRecentlyViewedClaim(claimId).then();
    }, [claimWithIncludes] // <-- Execute this method whenever claimId or SignedInUser changes.
  );

  const signedInSentimentUser: SentimentUser | undefined = getSignedInSentimentUser();

  /**
   * Fetch Contest Selections whenever the claim changes, but only if there's a signed-in user. This function is
   * required to show a new claim once the user navigates to the page. This also loads the claim choices for the first
   * time a page loads (e.g., direct-linking).
   */
  useEffect(() => {
    console.debug("Calling fetchClaimContestSelections()")
    // fetchClaimContestSelections handles the signed-in user check.
    fetchClaimContestSelections().catch(console.error);
  }, [signedInSentimentUser, claimWithIncludes]);

  /**
   * Helper method to update contest selection values in the UI while we're waiting for the server indexes to update.
   *
   * @param newClaimContestSelection A {@link ClaimContestSelection} that has been uploaded to the server, but for which
   * server-side indices may not yet have been updated (basically, if we did a simple reload from the server
   * immediately, the UI might display stale data. So, instead, we display this value instead).
   */
  const updateContestSelectionUI = (newClaimContestSelection: ClaimContestSelection) => {
    console.debug("Updating claimContestSelection with=" + JSON.stringify(newClaimContestSelection));

    if (claimWithIncludes !== undefined) {
      let previousClaimContestSelection: ClaimContestSelection | undefined =
        findContestSelectionByClaimId(claimContestSelections, newClaimContestSelection.claimId);
      if (previousClaimContestSelection === undefined) {
        console.warn("No previous contest selection found.");
        previousClaimContestSelection = newClaimContestSelection;
      }

      const updatedClaimContestSelection: ClaimContestSelection = updateContestMetricsStateOnVoteClick(
        claimWithIncludes,
        previousClaimContestSelection,
        newClaimContestSelection
      );
      // The helper helps replace an item in the Array so React can notice...
      setClaimContestSelectionHelper(updatedClaimContestSelection);
    } else {
      console.warn("updateContestSelection had undefined Claim(" + JSON.stringify(claimWithIncludes) + ")");
    }
  };

  const updateRecentlyViewedClaim = async (claimId: string) => {
    console.debug("Emitting RecentClaimView to sentiment...")

    const claim: Claim | undefined = claimWithIncludes?.data as Claim ?? undefined;
    if (claim === undefined || claim?.parentClaimId !== null) {
      console.debug("Skipping RecentClaimView update due to non-parent claim.");
      return Promise.resolve();
    }

    if (!isSignedIn()) {
      console.debug("Skipping RecentClaimView update.");
      return Promise.resolve();
    } else {

      const signedInSentimentUser: SentimentUser | undefined = getSignedInSentimentUser();
      if (signedInSentimentUser) {
        const url: string = fetchRecentlyViewedClaimsUrl(signedInSentimentUser.id);
        return sentimentAxios.post(url,
          {},
          {
            params: {"claimId": claimId}
          }
        ).then(response => {
          console.debug("POST RecentClaims RETURNED: " + JSON.stringify(response.data))
        }).catch(e => {
          // TODO: Use a Chakra Alert
          console.error("Error updating RecentClaims: " + e);
        });
      }
    }
  };

  const fetchClaimContestSelections = async () => {

    const claim: Claim | undefined = claimWithIncludes?.data as Claim ?? undefined;

    if (isSignedIn()) {
      if (!fetchingClaimContestSelections) {
        setFetchingClaimContestSelections(true);

        // Load all contest selections for the primary claim AND any subclaims found in the FirestoreClaim.
        const safeSubclaims = claimWithIncludes?.includes?.subclaims ?? {}; // because it might be undefined
        const claimIds: Map<string, Claim> = new Map(Object.entries(safeSubclaims));
        if (claim) { // <-- because this might be undefined...
          claimIds.set(claimId, claim);
        }
        if (claimIds.size > 0) {
          const signedInSentimentUser = getSignedInSentimentUser();
          if (!signedInSentimentUser) {
            console.error("No signedInSentimentUser");
            setFetchingClaimContestSelections(false);
            return Promise.resolve();
          }

          // signedInSentimentUser should always exist given the check above.
          const url: string = fetchClaimContestSelectionsUrl(signedInSentimentUser.id);
          console.debug("Fetching ClaimContestSelections from " + url)

          return sentimentAxios
            .get(url, {params: {"claimIds": Array.from(claimIds.keys()).join(",")}})
            .then(response => {
              console.debug("GET ClaimContestSelections RETURNED: " + JSON.stringify(response.data))
              const contestSelectionsArr: Array<ClaimContestSelection>
                = response.data as Array<ClaimContestSelection>;
              setClaimContestSelections(contestSelectionsArr);
            })
            .catch(e => {
              if (e.response && e.response.status === 404) {
                console.debug("No Claim found: " + e);
              } else {
                // TODO: Use a Chakra Alert
                console.debug("Error Fetching Claim: " + e);
              }
              // Uncomment to enable the timer that periodically loads ClaimContestSelections.
              // setIsActive(false);
            })
            .finally(() => {
              setFetchingClaimContestSelections(false);
            });
        } else {
          // TODO: Show an error or force a new login?
          console.error("No firebase user to get Id token for.")
          setFetchingClaimContestSelections(false);
          return Promise.resolve();
        }
      } else {
        console.debug("No claimIds found to fetch contest selections for.")
        setFetchingClaimContestSelections(false);
        return Promise.resolve();
      }
    } else {
      console.debug("No signedInSentimentUser to fetch contest selections for.")
      setFetchingClaimContestSelections(false);
      return Promise.resolve();
    }

  };

  /**
   * Helper method to update react state for a Map.
   * @param updatedClaimContestSelection
   */
  const setClaimContestSelectionHelper = (updatedClaimContestSelection: ClaimContestSelection) => {
    console.debug(
      "Updating ClaimContestSelections. previous=%s updated=%s",
      JSON.stringify(
        findContestSelectionByClaimId(claimContestSelections, updatedClaimContestSelection.claimId)
      ),
      JSON.stringify(updatedClaimContestSelection)
    );

    // For a Map
    // setClaimContestSelections(
    //     claimContestSelections => new Map(claimContestSelections.set(claimId, claimContestSelection))
    // );

    console.debug("Updating claim contest selection in memory...")
    // For an Array
    setClaimContestSelections(claimContestSelections.map(contestSelection => {
      if (contestSelection.claimId === updatedClaimContestSelection.claimId) {
        return updatedClaimContestSelection;
      } else {
        return contestSelection;
      }
    }));
  }

  const safeSubclaims = claimWithIncludes?.includes?.subclaims ?? {}; // because it might be undefined
  const subclaimsMap: Map<string, Claim> = new Map(Object.entries(safeSubclaims));
  subclaimsMap.forEach((claim: Claim, claimId: string) => {
    claim.id = claimId;
  });
  const subclaimsArr: Claim[] = Array.from(subclaimsMap.values());

  const flexBgColor = useColorModeValue("gray.50", "gray.800");
  const stackBgColorMode = useColorModeValue("white", "gray.700");
  // const headingBgColorMode = useColorModeValue("gray.800", "gray.200");

  type SentimentBreadcrumbItemProps = {
    parentClaimId?: string;
  };

  function SentimentBreadcrumbItem(props: SentimentBreadcrumbItemProps) {
    if (props.parentClaimId) {
      return <BreadcrumbItem>
        <BreadcrumbLink as={ReactRouterLink} to={"/claims/" + props.parentClaimId}>
          {props.parentClaimId}
        </BreadcrumbLink>
      </BreadcrumbItem>
    } else {
      return <BreadcrumbItem isCurrentPage><Text>n/a</Text></BreadcrumbItem>
    }
  }

  type SentimentBreadcrumbProps = {
    parentClaimId?: string;
  };

  function SentimentBreadcrumb(props: SentimentBreadcrumbProps) {
    return <HStack>
      <Box minW={"1rem"}>
        <Spinner
          display={fetchingClaimContestSelections ? "flex" : "none"}
          thickness="2px"
          speed="1s"
          emptyColor="gray.200"
          color="orange.500"
          size="sm"
        />
      </Box>
      <Breadcrumb separator={<ChevronRightIcon color="gray.500"/>}>
        <BreadcrumbItem>
          <Text fontWeight={"semibold"}>Parent Claim</Text>
        </BreadcrumbItem>
        {/* Don't wrap this in </BreadcrumbItem> becaues it will be handled internally */}
        <SentimentBreadcrumbItem parentClaimId={props.parentClaimId}/>
      </Breadcrumb>
    </HStack>;
  }

  // Sorter function for the subclaims list. Depending on the value of the `subClaimSortBy` state, this function will
  // delegate to the proper sorting function.
  function sortSubClaims(a: Claim, b: Claim) {
    if (subClaimSortBy === byAgreement) {
      return sortSubClaimsByAgreement(a, b);
    } else if (subClaimSortBy === byDisagreement) {
      return sortSubClaimsByDisagreement(a, b);
    } else if (subClaimSortBy === byNeutrality) {
      return sortSubClaimsByNeutralityWithFallbacks(a, b);
    } else if (subClaimSortBy === byNumAgreeVotes) {
      return sortSubClaimsByNumAgreeVotesWithFallback(a, b);
    } else if (subClaimSortBy === byNumDisagreeVotes) {
      return sortSubClaimsByNumDisagreeVotesWithFallback(a, b);
    } else {
      // Covers option `0` (which is the empty string) and option `1` which is by creation.
      return sortSubClaimsByCreationDateWithFallback(a, b);
    }
  }

  function SentimentSubclaims() {
    return <Fragment>
      <MetaTags claimWithIncludes={claimWithIncludes}/>
      <VStack
        align="stretch"
        w={{base: "375px", md: "640px", lg: "960px"}}
        px={"1rem"}
        pt={"1rem"}
        pb={"0rem"}
      >
        {subclaimsArr.length > 0 &&
          <Flex
            pr={"1"}
            pl={"1"}
            alignItems={"end"}
            // border={"1px"}
          >
            <Box
              // pt={"0.5rem"} pr={"0.5rem"} pb={"0rem"} pl={"0.5rem"}
            >
              <Heading size={"md"}>Subclaims</Heading>
            </Box>
            <Spacer/>
            <Box
              // p="0.5rem"
            >
              <Select
                placeholder='Sort By...'
                size={"sm"}
                onChange={(e) => {
                  setSubClaimSortBy(e.target.value);
                  console.debug("setSubClaimSortBy=" + e.target.value);
                }}
                value={subClaimSortBy}
              >
                <option value={byCreationDate}>Creation Date</option>
                <option value={byAgreement}>Agreement</option>
                <option value={byDisagreement}>Disagreement</option>
                <option value={byNeutrality}>Neutrality</option>
                <option value={byNumAgreeVotes}>Agree Votes</option>
                <option value={byNumDisagreeVotes}>
                  Disagree Votes
                </option>
              </Select>
            </Box>
          </Flex>
        }

        <List textAlign={"left"}>
          {subclaimsArr
            .sort(sortSubClaims)
            .map((subclaim: Claim) => <ListItem pb={"0.5rem"} key={subclaim.id}>
                <Divider/>
                <Stack
                  direction={["column", "row"]}
                >
                  <HStack w={"100%"}>
                    <Link as={ReactRouterLink}
                          to={"/users/" + getAuthorUserUsername(subclaim.authorId, claimWithIncludes)}>
                      <Avatar
                        size={"xs"}
                        src={getUserProfileImageUrl(getAuthorUser(subclaim.authorId, claimWithIncludes))}
                      />
                    </Link>
                    <VStack align={"left"}>
                      <Link as={ReactRouterLink} to={"/claims/" + subclaim.id}>
                        <Text
                          marginBottom={"-0.5rem"}
                          fontSize={"lg"}
                          whiteSpace={"normal"}
                          wordBreak={"break-word"}
                          overflowWrap={"anywhere"}
                          flexWrap={"wrap"}
                        >
                          {/*{subclaim.text}*/}
                          <ReactMarkdown
                            components={
                              {
                                // Make all href's blue
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                a: ({node, ...props}) =>
                                  <span style={{color: "blue"}} {...props}/>,
                                ol: ({node, ...props}) => <span>
                                  {props.start ? props.start : "1"}. {toString(node as Nodes)}
                                </span>,
                                ul: ({node, ...props}) => <span {...props}>
                                  {/*{props.start ? props.start : "*"}*/}
                                  * {toString(node as Nodes)}
                                </span>,
                                p: "span", // Can't embed a <p> in here.
                                // Map `h1` (`# heading`) to use `h2`s.
                                // h1: "h2",
                                // li: ({node}) => <span>{toString(node).toString()}</span>,
                                // h1: ({node, ...props}) => <i style={{color: "red"}}
                                // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                                // em: ({node, ...props}) => <i style={{color: "red"}} {...props}
                                // />
                              }
                            }
                            children={subclaim?.text ?? ""}/>
                        </Text>
                      </Link>
                      <SentimentSubclaimMetadata
                        numSubclaimsMessage={computeNumSubClaimsMessage(subclaim)}
                        claimCreationDate={computeDateDisplayString(subclaim.createdAt)}
                      />
                    </VStack>
                  </HStack>
                  <Center>
                    <SubclaimVoteButtonsContainer
                      buttonSize={"small"}
                      claimId={subclaim.id}
                      claim={subclaim}
                      claimContestSelection={
                        findContestSelectionByClaimId(claimContestSelections, subclaim.id)
                      }
                      onContestSelectionChange={updateContestSelectionUI}
                      isLoading={showClaimLoadingInterstitials || fetchingClaimContestSelections}
                    />
                  </Center>
                </Stack>
              </ListItem>
            )}
        </List>
      </VStack>
    </Fragment>
    // }
  }

  type SentimentCreatedByProps = {
    claimCreationDate: string;
  }

  function SentimentCreatedBy(props: SentimentCreatedByProps) {
    return (<Text padding={"0"} fontSize={"sm"}>
      <Text as={"span"}>Created </Text><b>{props.claimCreationDate}</b>
      {/*by&nbsp;*/}
      {/*<ReactRouterLink to={props.authorProfileUrl}>*/}
      {/*    <Text as={"span"} fontWeight={"bold"} color={"blue.400"}*/}
      {/*          textDecoration={"underline"}>*/}
      {/*        {props.authorUsername}*/}
      {/*    </Text>*/}
      {/*</ReactRouterLink>*/}
    </Text>);
  }

  type SentimentSubclaimCreatedByProps = {
    numSubclaimsMessage: string;
    claimCreationDate: string;
  }

  // Display metadata about a particular subclaim, such as the creation date and the number of subclaims.
  function SentimentSubclaimMetadata(props: SentimentSubclaimCreatedByProps) {
    return (<Text as={"span"} fontSize={"2xs"}>
      {props.numSubclaimsMessage} / {props.claimCreationDate}
    </Text>);
  }

  const claim: Claim | undefined = claimWithIncludes?.data as Claim ?? undefined;

  const computeShowClaimLoadingInterstitials = () => {
    return fetchingClaimInfo;
    // let showClaimLoadingInterstitials: boolean;
    // if (!claim && fetchingClaimInfo) {
    //   // No claim yet, but we're fetching, so show interstitials.
    //   showClaimLoadingInterstitials = true;
    // } else if (claim && fetchingClaimInfo) {
    //   // There is a claim, but we're fetching a new one...
    //   showClaimLoadingInterstitials = true;
    // } else {
    //   showClaimLoadingInterstitials = false;
    // }
    // return showClaimLoadingInterstitials;
  };
  const parentClaimId: string = claim?.parentClaimId ?? undefined;
  const showClaimLoadingInterstitials: boolean = computeShowClaimLoadingInterstitials();
  const claimTextColor = useColorModeValue("gray.800", "white.800");
  const mutedClaimTextColor = useColorModeValue("gray.300", "white.300");

  console.debug("Rendering Claim Text=" + claim?.text);
  if (claim || fetchingClaimInfo) { // <-- If there's a claim, or if there's no claim but we"re fetching it.
    // Here, there is a claim.
    return (
      <Flex
        minH={"50vh"}
        h={"max-content"}
        align={"center"}
        justify={"center"}
        paddingY={6}
        bg={flexBgColor}
      >
        <VStack
          boxShadow={"2xl"}
          bg={stackBgColorMode}
          rounded={"xl"}
          px={"2rem"}
          pt={"1rem"}
          align={"center"}
          maxW={{base: "375px", md: "640px", lg: "960px"}}
        >
          <SentimentBreadcrumb parentClaimId={parentClaimId}/>
          <VStack p={2} textAlign="center">
            <Center w={{base: "375px", md: "640px", lg: "960px"}}
                    margin={"0rem 0rem 1rem 0rem"}
                    padding={"0rem 1rem 0rem 1rem"}
            >
              <Heading
                color={showClaimLoadingInterstitials ? mutedClaimTextColor : claimTextColor}
                size="2xl"
                whiteSpace={"normal"}
                wordBreak={"break-word"}
                overflowWrap={"anywhere"}
                flexWrap={"wrap"}
                // style={{"font-color: red;"}
              >
                {/*{claim?.text ?? ""}*/}
                <ReactMarkdown
                  components={
                    {
                      // Make all href's blue
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      a: ({node, ...props}) =>
                        <a style={{color: "blue"}} {...props}/>,
                      ol: ({node, ...props}) => <span>
                                  {props.start ? props.start : "1"}. {toString(node as Nodes)}
                                </span>,
                      ul: ({node, ...props}) => <span {...props}>
                                  {/*{props.start ? props.start : "*"}*/}
                        * {toString(node as Nodes)}
                                </span>,
                      // Map `h1` (`# heading`) to use `h2`s.
                      // h1: "h2",
                      // li: ({node}) => <span>{toString(node).toString()}</span>,
                      // h1: ({node, ...props}) => <i style={{color: "red"}}
                      // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                      // em: ({node, ...props}) => <i style={{color: "red"}} {...props}
                      // />
                    }
                  }
                  children={claim?.text ?? ""}/>
              </Heading>
            </Center>
            <HStack maxWidth={"960px"}>
              <Link as={ReactRouterLink} to={"/users/"
                + getAuthorUserUsername(claim?.authorId ?? "", claimWithIncludes)}
              >
                {showClaimLoadingInterstitials ?
                  <SkeletonCircle size="24px"/> :
                  <Avatar
                    size={"xs"}
                    src={getUserProfileImageUrl(getAuthorUser(claim?.authorId ?? "", claimWithIncludes))}
                  />
                }
              </Link>
              <SentimentCreatedBy
                claimCreationDate={computeDateDisplayStringFromClaim(claim)}
              />
            </HStack>
            <Box>
              <VoteButtonsContainer
                signedInUserId={getSignedInSentimentUser()?.id}
                claimId={claimId}
                claim={claim}
                claimContestSelection={findContestSelectionByClaimId(claimContestSelections, claimId)}
                onContestSelectionChange={updateContestSelectionUI}
                isLoading={showClaimLoadingInterstitials || fetchingClaimContestSelections}
              />
            </Box>
            <CreateSubClaim parentClaimId={claimId}/>
            <SentimentSubclaims/>
            {subclaimsArr.length > 40 &&
              <CreateSubClaim parentClaimId={claimId}/>
            }
          </VStack>
        </VStack>
      </Flex>
    );
  } else {
    // No Claim and no longer fetching.
    return (
      <ClaimNotFound claimId={claimId}/>
    );
  }
}

export default ClaimViewFirestore;