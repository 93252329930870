import React from "react";
import {NavBar} from "./components/NavBar/NavBar";
import {Route, Routes} from "react-router-dom";
import {HOME, HOW_IT_WORKS, RECENT_CLAIMS, SLASH,} from "./model/constants";
import {
  SLASH_ADMIN_SLASH_ENV,
  SLASH_ADMIN_SLASH_FUNDING_REQUESTS,
  SLASH_CLAIMS_SLASH_COLON_ID,
  SLASH_COMPOSE_SLASH_CLAIM,
  SLASH_ERROR,
  SLASH_SIGN_IN,
  SLASH_SUBSCRIBE,
} from "./model/url_constants";
import RecentlyViewedClaimsView from "./views/RecentClaims/recent_claim_view";
import {Footer} from "./components/Footer";
import {AlphaDisclaimerBanner} from "./components/AlphaDisclaimer/alpha-disclaimer-banner";
import {HomePage} from "./views/Home/home_page";
import {ErrorView} from "./views/ErrorView/error";
import {SignInScreen} from "./components/auth/firebase/SignInScreen";
import CreateClaimView from "./views/CreateClaimView/create_claim_view";
import {ClaimType} from "./components/CreateClaim/create_claim";
import StripePricingTable from "./views/Checkout/stripe_pricing_table";
import PageNotFound from "./views/PageNotFoundView/page_not_found_view";
import PrivateRoutes from "./components/auth/PrivateRoutes";
import {useAuth} from "./components/auth/AuthContext";
import {Env} from "./views/Env/env";
import FundUserView from "./views/AdminView/funding_requests_view";
import ClaimCardViewFirestore from "./views/ClaimView/claim_view_firestore";
import WelcomeView from "./views/HowItWorksView/how_it_works";

export const SentimentRoutes = () => {

  const {getSignedInSentimentUser, isSigningIn} = useAuth();

  return (
    <div>
      <AlphaDisclaimerBanner/>
      <NavBar signedInSentimentUser={getSignedInSentimentUser()} isSigningIn={isSigningIn()}/>

      <Routes>
        <Route path={SLASH} element={<HomePage/>}/>
        <Route path={SLASH_SIGN_IN} element={<SignInScreen/>}/>
        {/* /how-it-works */}
        <Route path={HOW_IT_WORKS} element={<WelcomeView/>}/>
        {/* /claims/:id */}
        <Route path={SLASH_CLAIMS_SLASH_COLON_ID + "/*"} element={<ClaimCardViewFirestore/>}/>
        {/* Used only for testing */}
        <Route path={SLASH_ERROR} element={<ErrorView/>}/>

        <Route element={<PrivateRoutes/>}>
          {/*----------*/}
          {/*ADMIN Routes*/}
          {/*----------*/}
          <Route path={SLASH_ADMIN_SLASH_ENV} element={<Env/>}/>
          <Route path={SLASH_ADMIN_SLASH_FUNDING_REQUESTS} element={<FundUserView/>}/>
          {/*----------*/}
          {/*USER APP ROUTES*/}
          {/*----------*/}
          {/* /home */}
          <Route path={HOME} element={<RecentlyViewedClaimsView/>}/>
          {/* /recent-claims */}
          <Route path={RECENT_CLAIMS} element={<RecentlyViewedClaimsView/>}/>
          {/* /compose-claim */}
          <Route path={SLASH_COMPOSE_SLASH_CLAIM} element={<CreateClaimView claimType={ClaimType.Claim}/>}/>
          <Route path={SLASH_SUBSCRIBE} element={
            <StripePricingTable
              pricingTableId={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID ?? "not-supplied"}
              publishableKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "not-supplied"}
              customerEmail={getSignedInSentimentUser()?.email}
              clientReferenceId={getSignedInSentimentUser()?.id}
            />}
          />
        </Route>
        {/* 👇️ only match this when no other signedInUserIsLoaded match */}
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>

      <Footer/>
    </div>
  );
};

