import {Link as ReactRouterLink, NavigateFunction, useNavigate} from "react-router-dom";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Heading,
  HStack,
  List,
  ListItem,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import {RecentlyViewedClaimMeta} from "../../client/model/recent_claim_meta";
import {fetchRecentlyViewedClaimsUrl, SLASH_COMPOSE_SLASH_CLAIM, SLASH_HOW_IT_WORKS} from "../../model/url_constants";
import {firebaseApp} from "../../utils/firebase";
import {ClaimWithIncludes} from "../../client/model/claim";
import {doc, Firestore, getDoc, getFirestore, Timestamp} from "firebase/firestore";
import {FcLike} from "react-icons/fc";
import {useAuth} from "../../components/auth/AuthContext";
import sentimentAxios from "../../utils/axios";
import {SentimentUser} from "../../client/model";
import {
  computeDateDisplayString,
  computeNumSubClaimsMessage,
  getAuthorName,
  getAuthorUser,
  getAuthorUserUsername,
  getUserProfileImageUrl
} from "../ViewUtils/common_claim_view_utils";
import {AxiosResponse, isAxiosError} from "axios";
// import firebase from "firebase";
// import Firestore = firebase.firestore.Firestore;

interface RecentlyViewedClaim {
  lastViewedAt: Timestamp;
  claim: ClaimWithIncludes;
}

const RecentlyViewedClaimsView = () => {

  const renderNoRecentClaimsView = (navigate: NavigateFunction): JSX.Element => {
    return (
      <Stack
        w={{base: "350px", md: "640px", lg: "640px"}}
        bg={stackBgColorMode}
        // overflow={"hidden"}
        rounded={"md"}
        alignItems={"left"}
        p={"4"}
        m="4"
        boxShadow="lg"
        borderRadius="sm"
      >
        <Container maxW={"4xl"} alignItems={"left"}>
          <VStack alignItems={"left"}>
            <HStack as={"header"}>
              <Text
                fontWeight={600}
                fontSize={{base: "2xl", sm: "2xl", lg: "3xl"}}
              >Welcome!</Text>
              <FcLike size={"1.5rem"}/>
            </HStack>

            <Stack spacing={{base: 4, sm: 6}}
                   divider={
                     <StackDivider borderColor={useColorModeValue("gray.200", "gray.600")}/>
                   }>
              <VStack
                spacing={{base: 4, sm: 6}}
                alignItems={"left"}
              >
                <Text
                  alignItems={"left"} textAlign={"left"}
                  align={"left"} alignContent={"left"}
                  color={useColorModeValue("gray.500", "gray.400")}
                  fontSize={"2xl"}
                  fontWeight={"300"}>
                  Thanks for using Sentiment, we're so glad you're here.
                </Text>
              </VStack>
              <Stack direction={{base: "column", md: "row"}}>
                <Button colorScheme={"orange"} onClick={() => navigate(SLASH_COMPOSE_SLASH_CLAIM)}>
                  Make Your First Claim
                </Button>
                <Center>
                  <Text fontSize={"sm"}>(<Text as={"span"} color={"blue.600"} textDecoration={"underline"}>
                    <ReactRouterLink to={SLASH_HOW_IT_WORKS}>How it Works</ReactRouterLink>
                  </Text>)</Text>
                </Center>
              </Stack>
            </Stack>
          </VStack>
        </Container>
      </Stack>
    );
  }

  const db: Firestore = getFirestore(firebaseApp);

  // React State (use undefined to detect first load to only show loading screen on first load)
  // From Bard: useRef persists across renders, so is the best way to make sure we don't load data twice.
  const isFetchingRecentClaims = useRef(false);
  const [recentlyViewedClaims, setRecentlyViewedClaims] = useState<RecentlyViewedClaim[] | undefined>(undefined);

  const {getSignedInSentimentUser, isSignedIn} = useAuth();

  const navigate = useNavigate();
  const signedInSentimentUser: SentimentUser | undefined = getSignedInSentimentUser();

  useEffect(() => {
    (async () => {
      // Don't fetch multiple at the same time, and only fetch if there's a signed-in user that can provide a JWT to
      // axios.
      if (!isFetchingRecentClaims.current && isSignedIn()) {
        isFetchingRecentClaims.current = true;
        // Fetch the RecentlyViewedClaims
        const signedInSentimentUserId = getSignedInSentimentUser()?.id;
        if (signedInSentimentUserId) {
          try {
            const recentlyViewedClaimMetaArr: RecentlyViewedClaimMeta[] = await fetchRecentlyViewedClaimMeta(
              signedInSentimentUserId
            );
            // Then -> Make a single call to firestore.
            const hydratedClaims: Array<RecentlyViewedClaim>
              = await fetchMultipleClaimsFromFirestore(recentlyViewedClaimMetaArr);
            // Then -> Push the results onto recentlyViewedClaims
            setRecentlyViewedClaims(hydratedClaims);
          } catch (error) {
            // Set to `undefined` to show a loader screen; set to [] to show welcome screen. In this csae, showing the
            // loader is probably appropriate since we can't get recent claims anyway.
            setRecentlyViewedClaims(undefined);
            // console.error(error);
          }
        }
        isFetchingRecentClaims.current = false;
      }
    })();
  }, [signedInSentimentUser]);  // <-- Only trigger if signedInSentimentUser changes.

  // See this for async/await: https://itnext.io/async-and-await-in-javascript-the-extension-to-a-promise-f4e0048964ac
  // See this for IIFE: https://devtrium.com/posts/async-functions-useeffect

  const recentClaimLoadingBgColor = useColorModeValue("white", "gray.700");
  const claimTextColor = useColorModeValue("gray.800", "white.800");
  const stackBgColorMode = useColorModeValue("white", "gray.700");
  const createdAtColor = useColorModeValue("gray.500", "gray.500");
  const authorUsernameColor = useColorModeValue("gray.500", "gray.500");

  /**
   * Fetch the most recent claims for the signed in user. This function is only ever called under the correct conditions
   * (i.e., (a) a user is signed in that can provide a JWT to axios; (b) a different call to this function is not
   * currently in-flight).
   */
    // TODO: Fetch proper pagination
  const fetchRecentlyViewedClaimMeta =
      async (signedInSentimentUserId: string): Promise<Array<RecentlyViewedClaimMeta>> => {
        // NOTE: See doc above; the caller of this function guarantees necessary preconditions.
        console.debug("Fetching Recent Claims in progress.")
        const url: string = fetchRecentlyViewedClaimsUrl(signedInSentimentUserId);
        console.debug("Fetching RecentClaims from " + url)
        return sentimentAxios.get(url)
          .then((response: AxiosResponse<any, any> | void) => {
            if (response && response.data) {
              const axiosResponse = response as AxiosResponse;
              return (axiosResponse.data as Array<any>)
                .map(item => {
                  return {
                    claimId: item.claimId,
                    // This will be a string, so requires conversion...
                    lastViewedAt: Timestamp.fromDate(new Date(item.lastViewedAt)),
                  } as RecentlyViewedClaimMeta;
                });
            } else {
              if (isAxiosError(response)) {
                throw new Error("Whoops!");
              } else {
                return new Array<RecentlyViewedClaimMeta>();
              }
            }
          });
      };

  /**
   * Given {@link RecentlyViewedClaimMeta}, hydrate the actual claim information with data from Firestore.
   * @param recentlyViewedClaimMeta
   */
    // TODO: Store fetched claims in a small cache, e.g., 5 mins or localstorage to reduce reads.
  const fetchClaimFromFirestore =
      async (recentlyViewedClaimMeta: RecentlyViewedClaimMeta): Promise<(RecentlyViewedClaim | undefined)> => {
        const claimId: string = recentlyViewedClaimMeta.claimId;
        const docRef = doc(db, "claims", claimId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const claimWithIncludes: ClaimWithIncludes = docSnap.data() as ClaimWithIncludes;
          return {
            claim: {
              ...claimWithIncludes,
              data: {
                ...claimWithIncludes.data,
                id: claimId,
              }
            },
            lastViewedAt: recentlyViewedClaimMeta.lastViewedAt
          }
        } else {
          // docSnap.data() will be undefined in this case
          console.warn("No Firestore document with claimId=`%s`!", claimId);
          return undefined;
        }
      };

  /**
   * Given an array of {@link RecentlyViewedClaimMeta}, fetch each actual claim from firestore, in parallel.
   * @param recentlyViewedClaimMetas
   */
  const fetchMultipleClaimsFromFirestore =
    async (recentlyViewedClaimMetas: Array<RecentlyViewedClaimMeta>): Promise<Array<RecentlyViewedClaim>> => {
      // See https://stackoverflow.com/questions/69013319/
      // remove-undefined-resolved-promise-from-array-of-promise-in-typescript
      const results = await Promise.all(
        recentlyViewedClaimMetas.map((claimMeta: RecentlyViewedClaimMeta) => fetchClaimFromFirestore(claimMeta))
      );
      return results.filter((r): r is RecentlyViewedClaim => r !== undefined);
    };

  // TODO: Keeping this around for when we introduce pagination and using a Map is preferred.
  /**
   * Helper method to update the Map of claims loaded from Firestore.
   * @param claimWithIncludes
   */
  // const updateClaimInMap = (claimId: string, claimWithIncludes: ClaimWithIncludes, lastViewedAt: Timestamp) => {
  //     setRecentlyViewedClaims(
  //         (map: Map<string, RecentlyViewedClaim>) => new Map(map.set(claimId,
  //             {
  //                 lastViewedAt: lastViewedAt as Timestamp,
  //                 claim: {
  //                     ...claimWithIncludes,
  //                     data: {
  //                         ...claimWithIncludes.data,
  //                         id: claimId,
  //                     }
  //                 }
  //             }
  //         ))
  //     );
  // }

  /**
   * Helper method to update the Map of claims loaded from Firestore.
   * @param claimWithIncludes
   */
  // const removeClaimFromMap = (claimId: string) => {
  //     setClaimsWithIncludes((map: Map<string, ClaimWithIncludes>) => {
  //         map.delete(claimId);
  //         return new Map(map);
  //     });
  // }

  /**
   * Compare function for instances of RecentlyViewedClaim. This sorts by `lastViewedAt` in Descending order.
   * @param a
   * @param b
   */
  const sortByMostRecentlyViewed = (a: RecentlyViewedClaim, b: RecentlyViewedClaim): number => {
    if (b.lastViewedAt > a.lastViewedAt) {
      return 1;
    } else if (b.lastViewedAt < a.lastViewedAt) {
      return -1;
    } else {
      return 0;
    }
  }

  const showRecentClaimLoadingListItem = () => {
    if (isFetchingRecentClaims) {
      return <List><ListItem
        w={"full"}
        bg={recentClaimLoadingBgColor}
        boxShadow={"2xl"}
        rounded={"md"}
        p={4}
        m={2}
        overflow={"hidden"}
      >
        <Stack>
          <Skeleton height="20px"/>
          <Skeleton height="20px"/>
          <Skeleton height="20px"/>
        </Stack>
      </ListItem>
      </List>

    } else {
      return (<Box as={"span"}/>);
    }
  };

  const showRecentlyViewedClaims = (recentlyViewedClaims: RecentlyViewedClaim[]) => {
    return <List>
      {
        recentlyViewedClaims
          .sort(sortByMostRecentlyViewed)
          .map((recentlyViewedClaim: RecentlyViewedClaim) => {
            const claim: ClaimWithIncludes = recentlyViewedClaim.claim;
            return (<ListItem
                key={claim.data.id}
                w={{base: "375px", md: "640px", lg: "960px"}}
                bg={stackBgColorMode}
                boxShadow={"2xl"}
                rounded={"md"}
                p={4}
                m={4}
                overflow={"hidden"}
              >
                <Stack direction={"column"} spacing={2} align={"left"}>
                  <HStack>
                    <Text color={createdAtColor} fontSize={"xs"} fontStyle={"italic"}>
                      Viewed {computeDateDisplayString(recentlyViewedClaim.lastViewedAt)}
                      &nbsp;({computeNumSubClaimsMessage(claim.data)})
                    </Text>
                  </HStack>
                  <ReactRouterLink to={"/claims/" + claim.data.id}>
                    <Text fontSize={"xl"} color={claimTextColor}>
                      {claim.data.text}
                    </Text>
                  </ReactRouterLink>
                  <HStack>
                    <Stack direction={"row"}
                           spacing={2}
                           align={"center"}
                           width={"85%"}
                           verticalAlign={"middle"}
                    >
                      <AvatarGroup max={2}>
                        <Avatar
                          name={getAuthorName(claim.data.authorId, claim)}
                          src={getUserProfileImageUrl(getAuthorUser(claim.data.authorId ?? "", claim))}
                        />
                      </AvatarGroup>
                      <Stack direction={"column"} spacing={0} fontSize={"xs"}>
                        <Text
                          fontWeight={600}>{getAuthorName(claim.data.authorId, claim)}</Text>
                        <Text color={authorUsernameColor}>
                          {getAuthorUserUsername(claim.data.authorId, claim)}
                        </Text>
                      </Stack>
                    </Stack>
                  </HStack>
                </Stack>
              </ListItem>
            );
          })
      }
    </List>
  };

  // Show loading only on the first time.
  const showLoadingScreen = recentlyViewedClaims === undefined;
  // Show No Recents if not the first time, but 0 recent claims exist.
  const showNoRecentsScreen = recentlyViewedClaims?.length === 0;

  return (
    <Center>
      <VStack
        w={{base: "375px", md: "640px", lg: "960px"}}
      >
        <Box>
          <Box width={"full"} alignItems={"left"} padding={"1rem 1rem 0rem 1rem"}>
            <Heading size={"md"} fontWeight={"medium"} verticalAlign={"middle"} textAlign={"left"}>
              Recently Viewed Claims
            </Heading>
          </Box>
          <Divider/>
          {
            showLoadingScreen ? showRecentClaimLoadingListItem() :
              showNoRecentsScreen ? renderNoRecentClaimsView(navigate) : showRecentlyViewedClaims(recentlyViewedClaims)
          }
        </Box>
      </VStack>
    </Center>
  );
};

export default RecentlyViewedClaimsView;