import {KeyboardEvent, SetStateAction, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react"
import {fundUserUrl} from "../../model/url_constants";
import sentimentAxios from "../../utils/axios";

const FundUserView = () => {
  const [submitted, setSubmitted] = useState(false);
  const [usernameInputValue, setUsernameInputValue] = useState("");
  const [sntAmountInputValue, setSntAmountInputValue] = useState("");
  const [restCallErrorMsg, setRestCallErrorMsg] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  // handle changes to the "value" state via updating it with e.target.value
  const handleUsernameChange = useCallback((e: { target: { value: SetStateAction<string>; }; }) => {
    setUsernameInputValue(e.target.value)
    setSubmitted(false);
    setShowSuccessDialog(false);
  }, []);

  const handleSntAmountInputValue = useCallback((e: { target: { value: SetStateAction<string>; }; }) => {
    setSntAmountInputValue(e.target.value)
    setSubmitted(false);
    setShowSuccessDialog(false);
  }, []);

  const navigate = useNavigate();

  const fundAccount = async () => {
    setSubmitted(true);

    // Check the input...
    if (usernameInputValue === undefined || usernameInputValue.length <= 0) {
      setSubmitted(true);
      return;
    }

    if (sntAmountInputValue === undefined || sntAmountInputValue.length <= 0) {
      setSubmitted(true);
      return;
    }

    console.debug("Calling sentimentAxios...");
    sentimentAxios.post(fundUserUrl(),
      {
        "username": usernameInputValue,
        "amountSnt": sntAmountInputValue
      }
    ).then(response => {
      if (response.status >= 200 && response.status < 300) {
        setRestCallErrorMsg("");
        setShowSuccessDialog(true);
      } else {
        setRestCallErrorMsg(response.statusText);
        setSubmitted(true);
        setShowSuccessDialog(false);
      }
    }).catch(e => {
      console.debug("Error Funding User: " + JSON.stringify(e));
      setRestCallErrorMsg(e.message);
      setShowSuccessDialog(false);
    });
  }

  function handleEnterKeyPress<T = Element>(f: () => void) {
    return handleKeyPress<T>(f, "Enter")
  }

  function handleKeyPress<T = Element>(f: () => void, key: string) {
    return (e: KeyboardEvent<T>) => {
      if (e.key === key) {
        f()
      }
    }
  }

  const helpTextColor = useColorModeValue("white.600", "gray.500");

  let restCallErrorAlert;
  if (restCallErrorMsg !== "") {
    restCallErrorAlert = <Alert status="error"
                                variant="left-accent"
                                alignContent={"left"}
                                marginBottom={"1rem"}>
      <AlertIcon/>
      <Box>
        <AlertTitle>Oops...Unable to fund user</AlertTitle>
        <AlertDescription>{restCallErrorMsg}</AlertDescription>
      </Box>
    </Alert>;
  }

  let invalidInputsAlert;
  if (submitted && (
    sntAmountInputValue === undefined ||
    sntAmountInputValue.length <= 0 ||
    usernameInputValue === undefined ||
    usernameInputValue.length <= 0
  )
  ) {
    invalidInputsAlert = <Alert status="error"
                                variant="left-accent"
                                alignContent={"left"}
                                marginBottom={"1rem"}>
      <AlertIcon/>
      <Box>
        <AlertTitle>Oops...</AlertTitle>
        <AlertDescription>Funding Inputs must not be empty</AlertDescription>
      </Box>
    </Alert>;
  }

  let successDialog;
  if (showSuccessDialog) {
    successDialog = <Alert status="info"
                           variant="left-accent"
                           alignContent={"left"}
                           marginBottom={"1rem"}>
      <AlertIcon/>
      <Box>
        <AlertTitle>Success!</AlertTitle>
        <AlertDescription>Funding applied.</AlertDescription>
      </Box>
    </Alert>;
  }

  return (
    <div>
      <Stack direction="column" align="left">
        {invalidInputsAlert} {restCallErrorAlert} {successDialog}
      </Stack>
      <Stack direction="column" spacing={4} align="center">
        <InputGroup padding={"2rem"} size="sm">
          <Stack direction="column" spacing={4} align="center">
            <Input placeholder="Enter username to fund"
                   _placeholder={{color: helpTextColor, fontStyle: "italic"}}
                   value={usernameInputValue}
                   onChange={handleUsernameChange}
                   onKeyDown={handleEnterKeyPress(fundAccount)}
            />
            <Input placeholder="Enter amount to fund"
                   _placeholder={{color: helpTextColor, fontStyle: "italic"}}
                   value={sntAmountInputValue}
                   onChange={handleSntAmountInputValue}
                   onKeyDown={handleEnterKeyPress(fundAccount)}
            />
          </Stack>
        </InputGroup>
        <ButtonGroup>
          <Button
            colorScheme="orange"
            onClick={fundAccount}
          >
            Fund User Account
          </Button>
          <Button onClick={() => navigate(-1)}>Cancel</Button>
        </ButtonGroup>
      </Stack>
    </div>
  );
};

export default FundUserView;