import React from "react";
import {Button, Center, Flex, Heading, Stack, Text, useColorModeValue,} from "@chakra-ui/react"
import {SLASH} from "../../model/constants";
import {Link as ReactRouterLink} from "react-router-dom";
import {MdHome as HomeIcon} from "react-icons/md";

interface ClaimNotFoundProps {
    claimId: string,
}

/**
 * A component that allows a new claim to be created.
 * @param _props
 */

const ClaimNotFound = (
  // We keep this for now, even though it's unused.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: ClaimNotFoundProps
) => {

    return (
        <Flex
            align={"center"}
            justify={"center"}
            py={"2rem"}
            mr={"2rem"}
            ml={"2rem"}
            bg={useColorModeValue("gray.50", "gray.800")}>
            <Stack
                boxShadow={"2xl"}
                bg={useColorModeValue("gray.50", "gray.700")}
                rounded={"xl"}
                p={10}
                spacing={8}
                align={"center"}>
                <Stack align={"center"} spacing={2}>
                    <Heading
                        // fontSize={"2xl"}
                        color={useColorModeValue("gray.800", "gray.200")}>
                        Oops...
                    </Heading>
                    <Text color={"gray.500"}>
                        <Text as={"span"} fontWeight={"semibold"} textDecoration={"underline"}>Claim</Text>:
                        The page you asked for doesn"t exist.
                    </Text>
                </Stack>
                <Center>
                    <ReactRouterLink to={SLASH}>
                        <Button
                            leftIcon={<HomeIcon/>}
                            bg={"blue.400"}
                            color={"white"}
                            _hover={{bg: "blue.500"}}
                            _focus={{bg: "blue.500"}}
                        >
                            Back to Home
                        </Button>
                    </ReactRouterLink>
                </Center>
            </Stack>
        </Flex>
    );
};

export default ClaimNotFound;