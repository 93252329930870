import {
  Box,
  Center,
  chakra,
  Container,
  Flex,
  Heading,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react"
import React, {ReactElement} from "react";
import {FaTools} from "react-icons/fa";
import {MdSecurity} from "react-icons/md";

export const AlphaDisclaimerBanner = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  interface CardProps {
    heading: string;
    description: string;
    icon: ReactElement;
    href: string | undefined;
  }

  const Card = ({heading, description, icon}: CardProps) => {
    return (
      <Box
        maxW={{base: "full", md: "275px"}}
        w={"full"}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align={"start"} spacing={2}>
          <Flex
            w={16}
            h={16}
            align={"center"}
            justify={"center"}
            color={"white"}
            rounded={"full"}
            bg={useColorModeValue("gray.100", "gray.700")}>
            {icon}
          </Flex>
          <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={"sm"}>
              {description}
            </Text>
          </Box>
          {/*<Button variant={"link"} colorScheme={"blue"} size={"sm"}>*/}
          {/*  Learn more*/}
          {/*</Button>*/}
        </Stack>
      </Box>
    );
  };

  return (
    <Center
      py="2"
      px="3"
      bgGradient="linear(to-r,cyan.700, purple.500)"
      // bgGradient="linear(to-r,orange.300, purple.400)"
      // bgGradient="linear(to-r,orange.400, yellow.400)"
      // bgGradient="linear(to-r,orange.300, pink.400)"
      // bgGradient="linear(to-r,orange.400, red.400)"
      color="white"
      textAlign="center"
    >
      <Flex align="center" fontSize="sm">
        {/*<Text fontWeight="medium" maxW={{base: "32ch", md: "unset"}}>*/}
        {/*  /!*Welcome to Sentiment Alpha! 💎*!/*/}
        {/*  /!*This is an Alpha version of Sentiment 💎*!/*/}
        {/*  We're in Alpha 💎*/}
        {/*</Text>*/}
        <chakra.a
          flexShrink={0}
          href={"#"}
          onClick={onOpen}
          ms="6"
          bg="blackAlpha.300"
          color="whiteAlpha.900"
          fontWeight="semibold"
          px="3"
          py="1"
          rounded="base"
        >
          We're in Alpha 💎
        </chakra.a>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          motionPreset="slideInBottom"
          scrollBehavior={"inside"}
          size={"3xl"}

        >
          <ModalOverlay
            backdropFilter="blur(3px) brightness(100%) hue-rotate(0deg)"
          />
          <ModalContent>
            <ModalHeader>Sentiment Alpha</ModalHeader>
            <ModalCloseButton/>
            <ModalBody pb={6}>
              <Text>
                We hope you enjoy using this <Text as="span" color={"red"} fontWeight={"bold"}>Alpha</Text> version of
                Sentiment. As you use the platform, please keep the following in mind:
              </Text>

              <Container maxW={"3xl"} mt={12}>
                <Flex flexWrap="wrap" gridGap={6} justify="center">
                  <Card
                    heading={"Better Every Day"}
                    icon={<Icon as={FaTools} w={10} h={10} color="#4caf50"/>}
                    description={
                      "Sentiment is always improving, but you may come across bugs, broken items, or missing " +
                      "features. If you run into a problem, please let us know!"
                    }
                    href={"#"}
                  />
                  <Card
                    heading={"Share With Care"}
                    icon={<Icon as={MdSecurity} w={10} h={10} color="#fca311"/>}
                    description={
                      "When you share a Claim, anyone with access to its URL can view it. Please be mindful of who " +
                      "you share your Claims with."
                    }
                    href={"#"}
                  />
                  {/*<Card*/}
                  {/*  heading={"Personal Email for Now"}*/}
                  {/*  icon={<Icon as={FaUser} w={10} h={10} color="#2196F3"/>}*/}
                  {/*  description={*/}
                  {/*    "We recommend signing in with your personal email account during " +*/}
                  {/*    "this Alpha period instead " +*/}
                  {/*    "of your corporate work account."*/}
                  {/*  }*/}
                  {/*  href={"#"}*/}
                  {/*/>*/}
                </Flex>
              </Container>
              <Text pt={"8"}>
                If you have feedback, ideas, or feature requests, we would love to hear from you!
              </Text>
              <Text>
                <Link color={"blue"} href={"https://sentiment.fyi/claims/hGGzD8mlMgzkyFLRXhCr"}>
                  Make a claim about it here
                </Link> or get in touch via email at <Link color={"blue"}
                href={"mailto:hello@sentiment.fyi"}>hello@sentiment.fyi</Link>.
              </Text>
            </ModalBody>

            <ModalFooter/>
          </ModalContent>
        </Modal>

      </Flex>
    </Center>
  )
}