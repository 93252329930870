/* eslint-disable valid-jsdoc */
import {IncludeType} from "./includes";
import {SentimentResource} from "./sentiment_resource";
import {Claim, ClaimWithIncludes} from "./claim";
import FirestoreUser from "./firestore_user";

/**
 * Defines the kinds of Includes that can exist on a Claim.
 */
export interface WithIncludes<T extends Includes> {
  data: SentimentResource
  includes?: T
}

/**
 * Defines the kinds of Includes that can exist on a Claim.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Includes {
}

/**
 * Defines the kinds of Includes that can exist on a Claim.
 */
export interface ClaimIncludes extends Includes {
  users?: Map<string, FirestoreUser>
  subclaims?: Map<string, Claim> // <-- typed as Claim because there are no subclaims.
}

/**
 * Helper function to find all includes by type.
 * @param withIncludes
 * @param includeType
 */
export const findIncludesByType = (withIncludes: WithIncludes<any> | undefined, includeType: IncludeType):
  Array<SentimentResource> => {
  switch (includeType) {
    case IncludeType.USERS: {
      return withIncludes?.includes?.users || [];
    }
    default:
      return [] as SentimentResource[];
  }
};

/**
 * Helper function to find an include by its type and unique identifier.
 * @param withIncludes An array of includes to search through.
 * @param includeType The type of include.
 * @param id The identifier to match on.
 */
export const findIncludeByTypeAndId =
  (withIncludes: WithIncludes<any> | undefined, includeType: IncludeType, id: string): any | undefined => {
    if (withIncludes === undefined) {
      return undefined;
    } else {
      const includesArray: Array<SentimentResource> = findIncludesByType(withIncludes, includeType);
      const first = includesArray.find((obj: SentimentResource) => {
        return obj.id === id;
      });
      return first;
    }
  };

export const findUserInIncludesById = (withIncludes: WithIncludes<any> | undefined, userId: string):
  FirestoreUser | undefined => (findIncludeByTypeAndId(withIncludes, IncludeType.USERS, userId) as FirestoreUser);

// Given a ClaimWithIncludes, find the username for the specified `userId`.
export const findUserName = (userId: string, claimWithIncludes: ClaimWithIncludes): string | undefined => {
  const users: any = claimWithIncludes.includes?.users || {};
  const numUsers: number = Object.keys(users).length;

  if (numUsers > 0) {
    // console.log("Users had length=" + numUsers);
    // console.log("userId=" + userId);
    // console.log("users=" + JSON.stringify(users));
    const user = users[userId];
    if (user) {
      // functions.logger.log("Returning name=" + user.name);
      return user.name;
    } else {
      console.error("No user found in user includes with userId={}", userId);
      return undefined;
    }
  } else {
    console.error("Unable to find userId={} because numUsers was 0", userId);
    return undefined;
  }
  // eslint-disable-next-line semi
}
