import {SentimentUser} from "../../client/model";
import {SLASH_ME} from "../../model/url_constants";
import sentimentAxios from "../../utils/axios";
import {EventEmitter} from "events";
import {firebaseAuth} from "../../utils/firebase_auth";
import {AxiosError, isAxiosError} from "axios";

/**
 * Defines valid auth states for Sentiment authentication.
 */
export const enum AuthState {

  /**
   * Firebase user signed out
   */
  SIGNED_OUT,

  /**
   * Firebase user signing in
   */
  SIGNING_IN,

  /**
   * Firebase user signed in
   */
  SIGNED_IN,
}

/**
 * The current state of loading the Signed In Sentiment user.
 */
export const enum SignedInUserLoadingState {

  /**
   * Nothing is happening with the SignedInSentimentUser.
   */
  NOT_LOADING,

  /**
   * The SignedInSentimentUser is being loaded.
   */
  LOADING,

  /**
   * The SignedInSentimentUser is being reloaded.
   */
  RELOADING,

  /**
   * There was an error loading the Signed In SentimentUser.
   */
  LOADING_ERROR
}

/**
 * A singleton class that centralizes all information about {@link SentimentUser} representation of the
 * signedInSentimentUser that is signed in via Sentiment authentication (i.e., Firebase Auth).
 */
export class SentimentAuth {

  private static AUTH_STATE_CHANGED = "AuthStateChanged";
  private static SIGNED_IN_USER_STATE_CHANGED = "SignedInUserStateChanged";

  private static _instance: SentimentAuth;

  // SentimentAuth begins life in the signed out state.
  private authState: AuthState;
  private authLoadingState: SignedInUserLoadingState;

  private _authEventEmitter: EventEmitter;

  // The signed-in signedInSentimentUser (or undefined if not signedInSentimentUser is signed in).
  private signedInSentimentUser?: SentimentUser;

  /**
   * Prevent instantiation.
   * @private
   */
  private constructor() {
    this._authEventEmitter = new EventEmitter();
    this.authState = AuthState.SIGNED_OUT;
    this.authLoadingState = SignedInUserLoadingState.NOT_LOADING;
  }

  /**
   * Public accessor for this singleton.
   */
  public static get Instance() {
    if (!this._instance) {
      this._instance = new this();

      // Add a listener for any firebase auth changes....
      firebaseAuth.onAuthStateChanged(user => {
        if (!user) {
          // Firebase is reporting the user signed out.
          console.debug("FirebaseAuth User signed-out");
          this._instance.forceSignOut().then();
        } else {
          // Firebase is reporting the user signed in.
          console.debug("FirebaseAuth User Signed In [email=%s uid=%s]", user?.email, user?.uid);
          this._instance.setAuthStateHelper(AuthState.SIGNED_IN);
          this._instance.loadMe().then();
        }
      });
    }

    return this._instance;
  }

  public onAuthStateChange(listener: (newState: AuthState) => void) {
    this._authEventEmitter.on(SentimentAuth.AUTH_STATE_CHANGED, listener);
  }

  public onSignedInUserStateChange(listener: (newState: SignedInUserLoadingState) => void) {
    this._authEventEmitter.on(SentimentAuth.SIGNED_IN_USER_STATE_CHANGED, listener);
  }

  public removeSignedInUserStateChangeListener(listener: (newState: SignedInUserLoadingState) => void) {
    this._authEventEmitter.removeListener(SentimentAuth.SIGNED_IN_USER_STATE_CHANGED, listener);
  }

  public removeAllListeners() {
    this._authEventEmitter.removeAllListeners();
  }

  /**
   * Private helper method to ensure that the emitter is called whenever the {@link authState} is updated.
   * @param newAuthState A new current {@link AuthState}.
   * @private
   */
  private setAuthStateHelper(newAuthState: AuthState): void {
    // Only emit an event if the authState actually changes
    if (this.authState !== newAuthState) {
      this.authState = newAuthState;
      if (newAuthState === AuthState.SIGNED_OUT) {
        this.signedInSentimentUser = undefined;
      }
      this._authEventEmitter.emit(SentimentAuth.AUTH_STATE_CHANGED, newAuthState);
    }
  }

  /**
   * Private helper method to ensure that the emitter is called whenever the {@link authLoadingState} is updated.
   * @param newAuthLoadingState A new current {@link SignedInUserLoadingState}.
   * @private
   */
  private setSignedInUserStateHelper(newAuthLoadingState: SignedInUserLoadingState) {
    // Only emit an event if the authState actually changes
    if (this.authLoadingState !== newAuthLoadingState) {
      this.authLoadingState = newAuthLoadingState;
      this._authEventEmitter.emit(SentimentAuth.SIGNED_IN_USER_STATE_CHANGED, newAuthLoadingState);
    }
  }

  /**
   * Accessor for the currently signedIn signedInSentimentUser, if any.
   */
  public getSignedInSentimentUser(): SentimentUser | undefined {
    return this.signedInSentimentUser;
  }

  public isSignedIn(): boolean {
    return this.authState === AuthState.SIGNED_IN;
  }

  public isSigningIn(): boolean {
    return this.authState === AuthState.SIGNING_IN;
  }

  public isSignedOut(): boolean {
    return this.authState === AuthState.SIGNED_OUT;
  }

  /**
   * Reloads the Sentiment User data (for the currently signed in signedInSentimentUser) from the server via the
   * /me API endpoint.
   */
  public async loadMe(): Promise<void> {
    switch (this.authState) {
      case AuthState.SIGNING_IN:
      case AuthState.SIGNED_OUT: {
        // Do nothing, the user is signed out.
        console.debug("Skipping loadMe() due to AuthState=" + this.authState);
        return Promise.resolve();
      }
      case AuthState.SIGNED_IN: {
        const authLoadingState: SignedInUserLoadingState = this.signedInSentimentUser ?
          SignedInUserLoadingState.RELOADING : SignedInUserLoadingState.LOADING;

        return this.loadMeHelper(authLoadingState);
      }
      default: {
        console.debug("Skipping loadMe() due to unhandled AuthState=" + this.authState);
      }
    }
  }

  /**
   * Helper method to load or reload the /me endpoint; uses the supplied {@link SignedInUserLoadingState} during the
   * load.
   * @param signedInUserState An {@link AuthState} to use during loading.
   * @private
   */
  private async loadMeHelper(signedInUserState: SignedInUserLoadingState): Promise<void> {
    this.setSignedInUserStateHelper(signedInUserState);
    return await sentimentAxios.get(SLASH_ME)
      // Convert to a SentimentUser
      .then(response => {
        return {
          ...response.data
        } as SentimentUser;
      })
      // Handler any errors...
      .then(
        (signedInSentimentUser: SentimentUser | undefined) => {
          // If not signed in, then signedInSentimentUser will be undefined
          if (JSON.stringify(this.signedInSentimentUser) !== JSON.stringify(signedInSentimentUser)) {
            // Don't reassign the signedInUser unless it's changed (so that the useEffect isn't reloaded unnecessarily)
            this.signedInSentimentUser = signedInSentimentUser;
          }
          this.setSignedInUserStateHelper(SignedInUserLoadingState.NOT_LOADING);
        },
        (error: any) => {
          if (isAxiosError(error)) {
            if (error.code) {
              if (
                error.code === AxiosError.ERR_BAD_REQUEST || // <-- Error 400.
                error.code === AxiosError.ERR_NETWORK ||
                error.code === AxiosError.ECONNABORTED ||
                error.code === AxiosError.ERR_CANCELED ||
                error.code === AxiosError.ERR_BAD_RESPONSE ||
                error.code === AxiosError.ERR_DEPRECATED ||
                error.code === AxiosError.ETIMEDOUT
              ) {
                // In this instance, don't reset auth. It's possible this is simply due to a network error or on
                // mobile, putting the phone into a pocket. Regardless, we want to  wait until the server
                // affirmatively indicates whether a signedInSentimentUser is signed in or not via a response code,
                // so do nothing in this case.
                console.warn("Unable to communicate with Sentiment API", error.toJSON());
              } else if (
                error.code === AxiosError.ERR_BAD_OPTION ||
                error.code === AxiosError.ERR_BAD_OPTION_VALUE ||
                error.code === AxiosError.ERR_FR_TOO_MANY_REDIRECTS ||
                error.code === AxiosError.ERR_INVALID_URL ||
                error.code === AxiosError.ERR_NOT_SUPPORT
              ) {
                console.error("Error while communicating with the Sentiment API", error.toJSON());
                this.forceSignOut();
              }
            } else if (error.response) {
              // The request was made, and the server responded with a non-2xx status code not handled above. Don't
              // sign out here because this error response is likely transient.
              console.error("Unhandled response code while communicating with the Sentiment API", error.toJSON());
              // this.forceSignOut();
            } else if (error.request) {
              // The request was made, but no response was received. `error.request` will be an instance of
              // XMLHttpRequest in the browser and an instance of `http.ClientRequest` in node.js

              // In this instance, don't reset auth. It's possible this is simply due to a network error or on
              // mobile, putting the phone into a pocket. Regardless, we want to  wait until the server
              // affirmatively indicates whether a signedInSentimentUser is signed in or not via a response code,
              // so do nothing in this case.
              console.warn("Unable to communicate with Sentiment API", error.toJSON());
            } else {
              // Something happened in setting up the request.
              this.forceSignOut();
            }
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Non-AxiosError requesting `/me`: " + JSON.stringify(error));
            this.forceSignOut();
          }
          this.setSignedInUserStateHelper(SignedInUserLoadingState.LOADING_ERROR);
        });
  }

  /**
   * Helper method to for a sign out of Firebase.
   * @private
   */
  private async forceSignOut(): Promise<void> {
    return firebaseAuth.signOut().then(() => {
      console.debug("fireBaseAuth.signOut() completed successfully");
    }).catch(error => {
      console.error("Error signing out of FirebaseAuth: " + JSON.stringify(error));
    }).then(() => {
      this.setAuthStateHelper(AuthState.SIGNED_OUT);
      this.setSignedInUserStateHelper(SignedInUserLoadingState.NOT_LOADING);
    });
  }

}

// export const sentimentAuth = SentimentAuth.Instance;