import {SLASH_HOME} from "../model/url_constants";

const STORAGE_KEY = "PreviousLocationPath";

/**
 * A module that centralizes all "previous location" information.
 */
export const prevLocationTracker = {
  getPreviousLocationPath(): string {
    try {
      const retrievedLocationDataString = sessionStorage.getItem(STORAGE_KEY);
      // Type guard to ensure it's a string before parsing
      if (typeof retrievedLocationDataString === "string") {
        const parsedLocationData: { previousLocationPath: string } | null = JSON.parse(retrievedLocationDataString);
        return parsedLocationData?.previousLocationPath || SLASH_HOME;
      } else {
        // Handle cases where it's not a string (e.g., return SLASH_HOME)
        return SLASH_HOME;
      }
    } catch (error) {
      console.error("Error retrieving previous location path:", error);
      return SLASH_HOME;
    }
  },

  setPreviousLocationPath(previousLocationPath: string): void {
    console.debug("Setting previousLocationPath to " + previousLocationPath);
    sessionStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({previousLocationPath})
    );
  },
};
