/**
 * A singleton class that centralizes all information about {@link SentimentUser} representation of the
 * signedInSentimentUser that is signed in via Sentiment authentication (i.e., Firebase Auth).
 */
export class ClaimSuggestions {

  /** Delay in seconds between retrieving new suggestions. */
  private static readonly DELAY_SECONDS: number = 60;

  // These show up in the suggested gray text boxes...
  private static readonly claimSuggestions: string[] = [
    "My manager has 1:1s with me every 2 weeks",
    "We should use Sentiment for meeting agendas",
    "I can use Sentiment to gauge customer morale",
    "I can use Sentiment to organize my 1:1",
    "I should track my to do list in Sentiment",
    "I will share claims by slacking this URL",
    "Let's discuss new product features here",
    "Let's discuss our next off-site in Sentiment",
    "We should reduce our meeting frequency",
    "Our meetings should be 25 min instead of 30",
  ];

  private static readonly subclaimSuggestions: string[] = [
    "Let's have 1:1's every 2 weeks",
    "Let's track meeting agendas here",
    "Let's track customer morale here",
    "I'll organize 1:1 thoughts here",
    "I'll track my To Do List here",
    "I'll slack this URL to share it",
    "Let's track new product features",
    "Let's discuss our off-site here",
    "Let's only meet bi-weekly",
    "Let's meet for 25 mins and not 30",
  ];

  private lastClaimSuggestionRetrievedAt: Date;
  private lastSubclaimSuggestionRetrievedAt: Date;

  private claimIndex: number;
  private subclaimIndex: number;

  private static _instance: ClaimSuggestions;

  /**
   * Prevents direct instantiation of the class, enforcing the singleton pattern.
   * @private
   */
  private constructor() {
    this.lastClaimSuggestionRetrievedAt = new Date();
    this.lastSubclaimSuggestionRetrievedAt = new Date();

    this.claimIndex = this.newClaimIndex();
    this.subclaimIndex = this.newSubclaimIndex();
  }

  /**
   * Public accessor for this singleton.
   */
  public static get Instance() {
    if (!this._instance) {
      this._instance = new this();
    }

    return this._instance;
  }

  /**
   * Retrieves a suggested claim, ensuring at least DELAY_SECONDS have passed since the last suggestion.
   */
  public getSuggestedClaim(): string {
    const fifteenSecondsAgo = new Date(Date.now() - ClaimSuggestions.DELAY_SECONDS * 1000);
    if (this.lastClaimSuggestionRetrievedAt <= fifteenSecondsAgo) {
      this.claimIndex = this.newClaimIndex();
      this.lastClaimSuggestionRetrievedAt = new Date();
    }

    return ClaimSuggestions.claimSuggestions[this.claimIndex];
  }

  /**
   * Retrieves a suggested subclaim, ensuring at least DELAY_SECONDS have passed since the last suggestion.
   */
  public getSuggestedSubclaim(): string {
    const fifteenSecondsAgo = new Date(Date.now() - ClaimSuggestions.DELAY_SECONDS * 1000);
    if (this.lastSubclaimSuggestionRetrievedAt <= fifteenSecondsAgo) {
      this.subclaimIndex = this.newSubclaimIndex();
      this.lastSubclaimSuggestionRetrievedAt = new Date();
    }

    return ClaimSuggestions.subclaimSuggestions[this.subclaimIndex];
  }

  /**
   * Generates a random index within the range of available claim suggestions.
   */
  private newClaimIndex() {
    return Math.floor(Math.random() * ClaimSuggestions.claimSuggestions.length);
  }

  /**
   * Generates a random index within the range of available subclaim suggestions.
   */
  private newSubclaimIndex() {
    return Math.floor(Math.random() * ClaimSuggestions.subclaimSuggestions.length);
  }

}

export const claimSuggestions = ClaimSuggestions.Instance;