// Use the `sentiment:{subject}:{verb}` event pattern.
export enum SentimentEvents {
    // A claim was recently created.
    CLAIM_CREATED = "sentiment:claim:created",

    // A user voted for the first time.
    INITIAL_VOTE = "sentiment:claim:initial-vote",
}


function on(eventType: any, listener: any) {
    off(eventType, listener);
    console.log("document.addEventListener");
    document.addEventListener(eventType, listener);
}

function off(eventType: any, listener: any) {
    console.log("document.removeEventListener");
    document.removeEventListener(eventType, listener);
}

function once(eventType: string, listener: any) {
    on(eventType, handleEventOnce);

    function handleEventOnce(event: any) {
        listener(event);
        off(eventType, handleEventOnce);
    }
}

function trigger(eventType: string, data: any) {
    console.log("SentimentEvent Trigger. eventType=" + eventType + "; data=" + JSON.stringify(data));
    const event = new CustomEvent(eventType, data);
    document.dispatchEvent(event);
}

export {on, once, off, trigger};