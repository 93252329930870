import {ClaimContestOption, ClaimContestSelection} from "../../client/model";
import {ClaimWithIncludes} from "../../client/model/claim";
import {SentimentEvents, trigger} from "../../events/sentimentEvents";

/**
 * Given a {@link ClaimWithIncludes}, merge in the new claim contest selection so that the UI can display this to the
 * user while the system processes the vote behind the scenes.
 * @param claimWithIncludes The existing Claim object to update.
 * @param previousClaimContestSelection The prior instance of the user's vote choice (as loaded from the server).
 * @param newClaimContestSelection The latest instance of the user's choice (taken from the user's vote button click).
 */
export const updateContestMetricsStateOnVoteClick = (
  claimWithIncludes: ClaimWithIncludes,
  previousClaimContestSelection: ClaimContestSelection,
  newClaimContestSelection: ClaimContestSelection,
) => {
  // TODO: Account for VoteTotals that aren't numbers. In this case, we don't need to increment anything because,
  // for example, taking something like "2.3k" and adding one likely won't change the displayed value.
  const previousVoteTotals = {
    numExistingAgreeVotes: +claimWithIncludes.data.claimMetrics.numAgreeVotes,
    numExistingDisagreeVotes: +claimWithIncludes.data.claimMetrics.numDisagreeVotes
  }

  if (previousClaimContestSelection.selectedOption === newClaimContestSelection.selectedOption) {
    // console.debug("previousClaimContestSelection.numVotes=" + previousClaimContestSelection.numVotes);
    // Not Swaping, either Vote or Unvote
    if (previousClaimContestSelection.numVotes === "0") {
      incrementVote(newClaimContestSelection, previousVoteTotals);
      // Trigger a /me reload...
      // TODO: Delay this trigger to give time for the funding to take place?
      trigger(SentimentEvents.INITIAL_VOTE, newClaimContestSelection);
    } else {
      decrementVote(previousClaimContestSelection, newClaimContestSelection, previousVoteTotals);
    }
  } else {
    // Swapping, either Vote or Unvote
    if (previousClaimContestSelection.numVotes === "0") {
      // Add vote.
      incrementVote(newClaimContestSelection, previousVoteTotals);
    } else {
      // Swap votes...
      swapVote(newClaimContestSelection, previousVoteTotals);
    }
  }

  if (previousVoteTotals.numExistingAgreeVotes < 0) {
    previousVoteTotals.numExistingAgreeVotes = 0;
  }
  if (previousVoteTotals.numExistingDisagreeVotes < 0) {
    previousVoteTotals.numExistingDisagreeVotes = 0;
  }

  // Replace the ClaimContest vote values with the updated voting metrics.
  // console.debug("Before updating State: " + JSON.stringify(claimWithIncludes));
  // const updatedClaimWithIncludes = {
  //     ...claimWithIncludes,
  //     data: {
  //         ...claimWithIncludes.data,
  //         claimMetrics: {
  //             ...(claimWithIncludes.data as Claim).claimMetrics,
  //             numAgreeVotes: String(previousVoteTotals.numExistingAgreeVotes),
  //             formattedNumAgreeVotes: String(previousVoteTotals.numExistingAgreeVotes),
  //             numDisagreeVotes: String(previousVoteTotals.numExistingDisagreeVotes),
  //             formattedNumDisagreeVotes: String(previousVoteTotals.numExistingDisagreeVotes)
  //         }
  //     }
  // };

  return newClaimContestSelection as ClaimContestSelection;
}

const incrementVote = (
  contestSelection: ClaimContestSelection,
  vote_totals: { numExistingAgreeVotes: number, numExistingDisagreeVotes: number }
) => {
  // console.debug("incrementVote claimContestSelection=" + JSON.stringify(claimContestSelection));
  // console.debug("incrementVote vote_totals=" + JSON.stringify(vote_totals));

  if (contestSelection.selectedOption === ClaimContestOption.AGREE) {
    for (let i = 0; i < +contestSelection.numVotes; i++) {
      vote_totals.numExistingAgreeVotes++;
    }
  } else {
    for (let i = 0; i < +contestSelection.numVotes; i++) {
      vote_totals.numExistingDisagreeVotes++;
    }
  }

  console.debug("incrementVote result vote_totals=" + JSON.stringify(vote_totals));
}

const decrementVote = (
  previousContestSelection: ClaimContestSelection,
  contestSelection: ClaimContestSelection,
  vote_totals: { numExistingAgreeVotes: number, numExistingDisagreeVotes: number }
) => {
  // console.debug("decrementVote previousContestSelection=" + JSON.stringify(previousContestSelection));
  // console.debug("decrementVote claimContestSelection=" + JSON.stringify(claimContestSelection));
  // console.debug("decrementVote vote_totals=" + JSON.stringify(vote_totals));

  if (contestSelection.selectedOption === ClaimContestOption.AGREE) {
    for (let i = 0; i < +previousContestSelection.numVotes; i++) {
      vote_totals.numExistingAgreeVotes--;
    }
  } else {
    for (let i = 0; i < +previousContestSelection.numVotes; i++) {
      vote_totals.numExistingDisagreeVotes--;
    }
  }
  // console.debug("decrementVote result vote_totals=" + JSON.stringify(vote_totals));
}

const swapVote = (
  contestSelection: ClaimContestSelection,
  vote_totals: { numExistingAgreeVotes: number, numExistingDisagreeVotes: number }
) => {
  // console.debug("swapVote claimContestSelection=" + JSON.stringify(claimContestSelection));
  if (contestSelection.selectedOption === ClaimContestOption.AGREE) {
    for (let i = 0; i < +contestSelection.numVotes; i++) {
      vote_totals.numExistingAgreeVotes++;
    }
    for (let i = 0; i < +contestSelection.numVotes; i++) {
      vote_totals.numExistingDisagreeVotes--;
    }
  } else {
    for (let i = 0; i < +contestSelection.numVotes; i++) {
      vote_totals.numExistingAgreeVotes--;
    }
    for (let i = 0; i < +contestSelection.numVotes; i++) {
      vote_totals.numExistingDisagreeVotes++;
    }
  }
}

/**
 * Helper method to find a {@link ClaimContestSelection} (from an array of selections) by a claim identifier.
 * @param claimContestSelections
 * @param claimId
 * @return The contest selection, or else `undefined` if no selection exists.
 */
export const findContestSelectionByClaimId = (claimContestSelections: ClaimContestSelection[], claimId: string)
  : ClaimContestSelection | undefined => {
  const foundValue: any = claimContestSelections.find((claim) => {
    return claim.claimId === claimId;
  });
  if (foundValue) {
    // console.debug("Found ClaimContestSelection: " + JSON.stringify(foundValue));
    return foundValue as ClaimContestSelection;
  } else {
    // console.debug("No ClaimContestSelection found in array of all selections.");
    return undefined;
  }
}

// /**
//  * Determines if a string value is a number.
//  * @param value
//  */
// function isNumber(value: string | number): boolean {
//   return ((value != null) &&
//     (value !== "") &&
//     !isNaN(Number(value.toString())));
// }