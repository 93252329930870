import React, {useEffect, useState} from "react";
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  CreateToastFnReturn,
  Flex,
  Heading,
  HStack,
  Link as ChakraLink,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack
} from "@chakra-ui/react";
import {onAuthStateChanged, User} from "firebase/auth";
import {FIREBASE_AUTH_UI_CONFIG, firebaseAuth} from "../../../utils/firebase_auth";
import {SLASH_SIGN_IN} from "../../../model/url_constants";
import {Navigate, useLocation} from "react-router-dom";
import {isEmailVerified, sendEmailVerificationHelper} from "./firebase_auth_utils";
import {prevLocationTracker} from "../../../utils/location_utils";

/**
 * Render the FirebaseAuthUI component, which renders a StyledFirebaseAuth component.
 */
const renderFirebaseAuthUI = () => {
  return (
    <Stack align={"center"}>
      <StyledFirebaseAuth
        uiConfig={FIREBASE_AUTH_UI_CONFIG}
        firebaseAuth={firebaseAuth}
        // uiCallback={ui => ui. <something with ui> }
      />
    </Stack>
  );
}

/**
 * Render the SignInError component.
 */
const renderSignInError = (): JSX.Element => {
  const flexBgColor = useColorModeValue("gray.50", "gray.800");
  // const stackBgColorMode = useColorModeValue("white", "gray.700");

  return (<Flex
      minH={"50vh"}
      h={"max-content"}
      align={"center"}
      justify={"center"}
      paddingY={6}
      bg={flexBgColor}
    >
      <Card align="center">
        <CardBody>
          <VStack>
            <Center>
              <Heading size={"lg"}>Oops, something went wrong</Heading>
            </Center>
            <VStack>
              <Text align={"center"}
                    width={"80%"} padding={"1rem"}>
                Please click
                <Button
                  as={"a"}
                  fontWeight={400}
                  variant={"link"}
                  colorScheme={"blue"}
                  textDecoration={"underline"}
                  href={SLASH_SIGN_IN}
                >
                  here
                </Button>
                to try again, or reload the page.</Text>

              <Text fontSize={"medium"} paddingBottom={"0.5rem"} paddingTop={"2rem"}>Still having
                problems?</Text>
              <Text paddingTop={"0.5rem"}>Need Help? <ChakraLink color={"blue.500"} textDecoration={"underline"}
                                                                 href={"mailto:hello@sentiment.fyi"}>Contact
                Us</ChakraLink>
              </Text>
            </VStack>
          </VStack>
        </CardBody>
      </Card>
    </Flex>
  );
}

/**
 * Render the VerifyEmailUI component.
 */
const renderVerifyEmailUI = (toast: CreateToastFnReturn, signedInUser: User, flexBgColor: string): JSX.Element => {
  return (
    <Flex
      minH={"50vh"}
      h={"max-content"}
      align={"center"}
      justify={"center"}
      paddingY={6}
      bg={flexBgColor}
    >
      <Card align="center">
        <CardBody>
          <VStack>
            <Center>
              <Heading size={"lg"}>Please verify your email</Heading>
            </Center>
            <VStack m={"1.5rem"} lineHeight={"1"}>
              <Text>You're almost there! We sent an email to</Text>
              <Text fontWeight={"bold"}>{signedInUser.email}</Text>
            </VStack>
            <VStack>
              <Text align={"center"} width={"80%"} padding={"1rem"}>
                Just click the link in that email to complete your signup. If you
                don't see the email, you may need to check your spam folder.</Text>

              <HStack>
                <Box
                  textAlign={"center"}
                  alignItems={"center"}
                  alignContent={"center"}
                  ml={"2rem"}
                  mr={"2rem"}
                >
                  <Text
                    fontSize={"medium"}
                    paddingBottom={"0.5rem"}
                    paddingTop={"2rem"}
                  >Still can find the email?</Text>

                  <Button
                    colorScheme="orange"
                    onClick={() => sendEmailVerificationHelper(signedInUser)
                      .catch(error => {
                        toast({
                          title: "Oops, something went wrong",
                          description: "We weren't able to send your verification email. Please try again. Error was: "
                            + error.description,
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                      })
                      .then(() => {
                        toast({
                          title: "Verification Email Resent",
                          description:
                            "We sent another verification email. Click the link in that email to complete your signup.",
                          status: "success",
                          duration: 5000,
                          isClosable: true,
                        });
                      })}
                  >Resend Email</Button>
                </Box>
                <Box
                  textAlign={"center"}
                  alignItems={"center"}
                  alignContent={"center"}
                  ml={"2rem"}
                  mr={"2rem"}
                >
                  <Text fontSize={"medium"} paddingBottom={"0.5rem"} paddingTop={"2rem"}>Email already verified?</Text>

                  <ChakraLink href={"/sign_in"}>
                    <Button colorScheme="blue">Sign in</Button>
                  </ChakraLink>

                </Box>
              </HStack>

              <Text
                textAlign={"center"}
                alignItems={"center"}
                alignContent={"center"}
                paddingTop={"2rem"}>Need Help? <ChakraLink color={"blue.500"} textDecoration={"underline"}
                                                           href={"mailto:hello@sentiment.fyi"}>Contact Us</ChakraLink>
              </Text>
            </VStack>
          </VStack>
        </CardBody>
      </Card>
    </Flex>
  );
}

export function SignInScreen() {
  // We want to trigger an emailVerification automatically on the first render where a user is not verified.
  // Therefore, we utilize a useFffect to set this value, but only when the authResult changes.
  const [signedInUser, setSignedInUser] = useState<User>();
  const [verificationEmailTriggered, setVerificationEmailTriggered] = useState(false);

  // It can be difficult to distinguish between "a user signed properly via Firebasae Auth, but then got signed out"
  // (e.g., the Firebase Auth/GoogleAuth session is valid, but the API returned a 401). vs. "the user wasn't able to
  // sign in via Firebase/Google". This latter condition is an error, and the user simply needs to retry. However, we
  // don't have a good way to simply send the user back to the sign-in buttons because that state won't re-render.
  // In addition, we want the user to see an error message with some instructions for what to do next. Thus, to detect
  // that condition, we set a boolean if the auth session ever comes back with a valid user. This signals that if a
  // user isn't signed in, then we should show an error.
  const [initialFirebaseAuthSignInSucceeded, setInitialFirebaseAuthSignInSucceeded] = useState(false);
  const toast: CreateToastFnReturn = useToast()

  useEffect(() => {
    // Only send if the user's email is not verified.
    if (signedInUser && !isEmailVerified(signedInUser)) {
      // No error check needed because this method display an error toast
      sendEmailVerificationHelper(signedInUser, toast).then(undefined);

      // TODO: This seems like it's in the wrong place...
      setVerificationEmailTriggered(true);
    }
  }, [signedInUser]);

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (user && !signedInUser) {
        setSignedInUser(user);
        setInitialFirebaseAuthSignInSucceeded(true);
      }
      return () => {
        unregisterAuthObserver();
      };
    });
  }, []); // <-- Only fire once

  const flexBgColor = useColorModeValue("gray.50", "gray.800");
  // const stackBgColorMode = useColorModeValue("white", "gray.700");

  const location = useLocation();
  console.debug("location: " + JSON.stringify(location));

  // const navigate = useNavigate();

  if (signedInUser) {
    if (isEmailVerified(signedInUser)) {
      // If we get here, it means that a user is signed in with a verified email address. So, simply redirect to /home.
      //
      // NOTE: Firebase's signInWithRedirect() always redirects to the same page that you start from, which in our case
      // is `/sign_in`. Ordinarily, you have to call getRedirectResult() and then redirect to your app on login.
      // However, if we allow Google/Firebase to do this, then there are at least three redirects which trigger
      // reloads of /me. Instead, we can simply query if the user is signed in (above) and then use react-router to
      // send the user home.

      // For previous URL tracking, we used to use the react-router state. However, in production, when we redirect
      // away to Google for login, the react-router state is nulled out. So, instead we use the prevLocationTracker,
      // which stores data into sessionStorage.
      const from = prevLocationTracker.getPreviousLocationPath();
      console.debug("Redirecting signed in user to " + from);
      return <Navigate replace to={from}/>;
    } else {
      // If a user is signed in, but not email verified, then an email will be triggered, and state updated.
      return renderVerifyEmailUI(toast, signedInUser, flexBgColor);
    }
  } else { // <-- User not signed-in.
    if (verificationEmailTriggered) {
      return renderSignInError(); // <-- This is an error...
    } else {
      // If we get here after having shown the FirebaseAuthUI buttons, then those buttons won't show up again without
      // clicking the `sign in` button or reloading the page. This is fine for now, but in the future we might consider
      // showing something on this page because it will just be blank. In general, this only happens the `signedInUser`
      // isn't being set into state, which triggers hook useEffect execution, so this really shouldn't ever happen.
      if (initialFirebaseAuthSignInSucceeded) {
        return renderSignInError();
      } else {
        return renderFirebaseAuthUI();
      }
    }
  }
}